/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import globalEs from 'translations/es/global.json';
import globalEn from 'translations/en/global.json';
import footerEs from 'translations/es/footer.json';
import footerEn from 'translations/en/footer.json';
import navbarEs from 'translations/es/navbar.json';
import navbarEn from 'translations/en/navbar.json';
import { getCurrentLanguage } from 'components/ToggleLanguege';
import { FilesProvider } from 'contexts/Files';
import { ToastContainer } from 'react-toastify';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const setLanguage = () => {
  const lang = getCurrentLanguage();
  if (lang) {
    localStorage.setItem('i18nextLng', lang);
  } else {
    localStorage.setItem('i18nextLng', navigator.language.split('-')[0]);
  }
};

setLanguage();

i18next.init({
  interpolation: {
  },
  lng: getCurrentLanguage(),
  supportedLngs: ['es', 'en'],
  fallbackLng: 'es',
  debug: true,
  resources: {
    es: {
      global: globalEs,
      footer: footerEs,
      navbar: navbarEs,
    },
    en: {
      global: globalEn,
      footer: footerEn,
      navbar: navbarEn,
    },
  },
}, (err) => {
  // eslint-disable-next-line no-console
  if (err) return console.log('something went wrong loading', err);
  return null;
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <FilesProvider>
        <App />
        <ToastContainer />
      </FilesProvider>
    </I18nextProvider>
  </React.StrictMode>,
);
