import { z } from 'zod';
import { getCurrentLanguage } from 'components/ToggleLanguege';
import { sangradoOptions } from 'utils/enums';

export const medicalHistorySchema = () => {
  const lenguage = getCurrentLanguage();
  const isSpanish = lenguage === 'es';
  const requiredMessage = isSpanish ? 'Este campo es requerido' : 'This field is required';

  const handleEnumError = (issue) => {
    if (issue.code === z.ZodIssueCode.invalid_enum_value) {
      return { message: requiredMessage };
    }
    if (issue.code === z.ZodIssueCode.invalid_type) {
      return { message: requiredMessage };
    }
    return { message: issue.message ?? '' };
  };

  return z.object({
    addictions: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    addictionsWhich: z.string().min(1, { message: requiredMessage }).optional(),
    highLipids: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    highLipidsDate: z
      .string()
      .min(1, { message: requiredMessage })
      .refine(
        (value) => {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        },
        { message: requiredMessage },
      ).optional(),
    highLipidsTreatment: z.string().min(1, { message: requiredMessage }).optional(),
    arthritis: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    arthritisDate: z
      .string()
      .min(1, { message: requiredMessage })
      .refine(
        (value) => {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        },
        { message: requiredMessage },
      ).optional(),
    arthritisTreatment: z.string().min(1, { message: requiredMessage }).optional(),
    cholesterolAndTriglycerides: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    cholesterolAndTriglyceridesDate: z
      .string()
      .min(1, { message: requiredMessage })
      .refine(
        (value) => {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        },
        { message: requiredMessage },
      ).optional(),
    cholesterolAndTriglyceridesTreatment: z.string().min(1, { message: requiredMessage }).optional(),
    stroke: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    strokeDate: z
      .string()
      .min(1, { message: requiredMessage })
      .refine(
        (value) => {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        },
        { message: requiredMessage },
      ).optional(),
    strokeTreatment: z.string().min(1, { message: requiredMessage }).optional(),
    diabetes: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    diabetesDate: z
      .string()
      .min(1, { message: requiredMessage })
      .refine(
        (value) => {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        },
        { message: requiredMessage },
      ).optional(),
    diabetesTreatment: z.string().min(1, { message: requiredMessage }).optional(),
    chronicArteryDisease: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    chronicArteryDiseaseDate: z
      .string()
      .min(1, { message: requiredMessage })
      .refine(
        (value) => {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        },
        { message: requiredMessage },
      ).optional(),
    chronicArteryDiseaseTreatment: z.string().min(1, { message: requiredMessage }).optional(),
    liverDisease: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    liverDiseaseDate: z
      .string()
      .min(1, { message: requiredMessage })
      .refine(
        (value) => {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        },
        { message: requiredMessage },
      ).optional(),
    liverDiseaseTreatment: z.string().min(1, { message: requiredMessage }).optional(),
    lungDisease: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    lungDiseaseDate: z
      .string()
      .min(1, { message: requiredMessage })
      .refine(
        (value) => {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        },
        { message: requiredMessage },
      ).optional(),
    lungDiseaseTreatment: z.string().min(1, { message: requiredMessage }).optional(),
    kidneyDisease: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    kidneyDiseaseDate: z
      .string()
      .min(1, { message: requiredMessage })
      .refine(
        (value) => {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        },
        { message: requiredMessage },
      ).optional(),
    kidneyDiseaseTreatment: z.string().min(1, { message: requiredMessage }).optional(),
    thyroidProblems: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    thyroidProblemsDate: z
      .string()
      .min(1, { message: requiredMessage })
      .refine(
        (value) => {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        },
        { message: requiredMessage },
      ).optional(),
    thyroidProblemsTreatment: z.string().min(1, { message: requiredMessage }).optional(),
    hypertension: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    hypertensionDate: z
      .string()
      .min(1, { message: requiredMessage })
      .refine(
        (value) => {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        },
        { message: requiredMessage },
      ).optional(),
    hypertensionTreatment: z.string().min(1, { message: requiredMessage }).optional(),
    otherDisease: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    otherDiseases: z.array(z.object({
      wich: z.string().min(1, requiredMessage).optional(),
      date: z
        .string()
        .min(0, { message: requiredMessage })
        .refine((value) => {
          const formValues = JSON.parse(localStorage.getItem('FORM_VALUES_HISTORIAL_MEDICO')) || {};
          if (formValues.otherDisease === 'true') {
            const date = new Date(value);
            return !Number.isNaN(date.getTime());
          }
          return true;
        }, { message: requiredMessage }).optional(),
      treatment: z.string().min(1, { message: requiredMessage }).optional(),
    })).optional(),
    // Solo si es mujer
    lastMenstrualPeriodDay: z
      .string()
      .min(1, { message: requiredMessage })
      .refine(
        (value) => {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        },
        { message: requiredMessage },
      ).optional(),
    sangrado: z.enum(sangradoOptions.map((option) => option.value), {
      errorMap: handleEnumError,
    }).optional(),
    pregnant: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
    hasBeenPregnant: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
    pregnantTimes: z.string().min(1, { message: requiredMessage }).optional(),
    cesareanSections: z.string().min(1, { message: requiredMessage }).optional(),
    contraceptives: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
    contraceptivesMethods: z.array(z.object({
      method: z.string().min(1, { message: requiredMessage }).optional(),
      timeOfUse: z.string().min(1, { message: requiredMessage }).optional(),
    })).optional(),
    hormones: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
    wichHormones: z.array(z.object({
      type: z.string().min(1, { message: requiredMessage }).optional(),
      timeOfUse: z.string().min(1, { message: requiredMessage }).optional(),
    })).optional(),
  });
};
