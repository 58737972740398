import { getCurrentLanguage } from 'components/ToggleLanguege';
import { metricSystemOptions } from 'utils/enums';
import { z } from 'zod';

export const healthDetailsFormSchema = () => {
  const lang = getCurrentLanguage();
  const isSpanish = lang === 'es';
  const requiredMessage = isSpanish ? 'Este campo es requerido' : 'This field is required';

  const handleEnumError = (issue) => {
    if (issue.code === z.ZodIssueCode.invalid_enum_value) {
      return { message: requiredMessage };
    }
    if (issue.code === z.ZodIssueCode.invalid_type) {
      return { message: requiredMessage };
    }
    return { message: issue.message ?? '' };
  };

  return z.object({
    measurementSystem: z.enum(metricSystemOptions.map((option) => option.value), {
      errorMap: handleEnumError,
    }),
    maxWeight: z.string().min(1, { message: requiredMessage }),
    currentWeight: z.string().min(1, { message: requiredMessage }),
    height: z.string().min(1, { message: requiredMessage }),
    takesMedications: z.enum(['true', 'false'], {
      errorMap: handleEnumError,
    }),
    medications: z.array(z.object({
      name: z.string().min(1, { message: requiredMessage }).optional(),
      dose: z.string().min(1, { message: requiredMessage }).optional(),
      frequency: z.string().min(1, { message: requiredMessage }).optional(),
      reason: z.string().min(1, { message: requiredMessage }).optional(),
    })).optional(),
    refluxProblems: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    anticoagulants: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    anticoagulantsReason: z.string().min(1, { message: requiredMessage }).optional(),
    penicillin: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    sulfasDrugs: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    iodine: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    tape: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    latex: z.enum(['true', 'false'], { errorMap: handleEnumError }),
    aspirin: z.enum(['true', 'false'], { errorMap: handleEnumError }),
  });
};
