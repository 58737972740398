import { useTranslation } from 'react-i18next';

export const getCurrentLanguage = () => localStorage.getItem('i18nextLng');

const ToggleLanguege = () => {
  const { i18n } = useTranslation();
  const language = localStorage.getItem('i18nextLng');
  const changeLanguage = (lng) => {
    localStorage.setItem('i18nextLng', lng);
    i18n.changeLanguage(lng);
  };
  return (
    <div>
      {language !== 'es' && (
        <button className="bg-primary-100 text-[#fff] disabled:opacity-50 h-auto p-4 rounded-lg font-semibold" type="button" onClick={() => changeLanguage('es')}>
          Español
        </button>
      )}
      {language !== 'en' && (
        <button className="bg-primary-100 text-[#fff] disabled:opacity-50 h-auto p-4 rounded-lg font-semibold" type="button" onClick={() => changeLanguage('en')}>
          English
        </button>
      )}
    </div>
  );
};

export default ToggleLanguege;
