export const getFormValues = (form) => {
  const stringForm = localStorage.getItem(form);
  return JSON.parse(stringForm) || {};
};

export const selectServices = () => {
  const stringServices = localStorage.getItem('SERVICES');
  return JSON.parse(stringServices) || [];
};

export const saveClient = (clientID) => {
  localStorage.setItem('CLIENT_ID', clientID);
};

export const setStep = (step) => {
  localStorage.setItem('STEP', step);
};

export const getStep = () => {
  const stringStep = localStorage.getItem('STEP');
  return stringStep || '';
};
