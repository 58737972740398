import React from 'react';

const Title = ({ text, level = 1 }) => {
  if (level === 4) {
    return <h4 className="text-lg text-primary-100 font-bold">{text}</h4>;
  }

  if (level === 3) {
    return <h3 className="text-xl text-primary-100 font-semibold">{text}</h3>;
  }

  if (level === 2) {
    return <h2 className="text-2xl text-primary-100 font-semibold">{text}</h2>;
  }

  return <h1 className="text-4xl text-primary-100 font-bold ">{text}</h1>;
};

export default Title;
