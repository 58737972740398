import { FilesContext } from 'contexts/Files';
import { useContext } from 'react';

const Input = ({
  label,
  type,
  id,
  error,
  register,
  required,
  col = 1,
  ...rest
}) => {
  const { setFiles } = useContext(FilesContext);

  if (type === 'file') {
    const { name, onBlur, onChange, ref } = register(id);

    return (
      <div className={`mt-1 sm:col-span-1 md:col-span-${col}`}>
        <label
          htmlFor={id}
          className="block mb-2 text-md font-medium text-gray-900 text-pretty"
        >
          {label}
          {required && <span className="text-red ml-1">*</span>}
        </label>
        <input
          type="file"
          id={id}
          accept="image/*,.pdf"
          name={name}
          onBlur={onBlur}
          onChange={(e) => {
            onChange(e);
            setFiles((prev) => ({ ...prev, [id]: e.target.files[0] }));
          }}
          ref={ref}
          className={`
          block w-full border rounded-md text-sm disabled:opacity-50 disabled:pointer-events-none 
          h-[58px]

          file:bg-slate-100
          file:border-0
          file:me-4
          file:py-3 file:px-4
          file:h-full
          file:border-primary-500
          file:ring-primary-300
          file:ring-opacity-50
          file:ring-1
          file:text-md
          file:text-gray-900
          file:cursor-pointer
          file:focus:ring-opacity-50
          file:focus:outline-none
          file:focus:border-primary-500

          bg-slate-100
          text-gray-900 cursor-pointer 
          focus:outline-none
          focus:border-primary-500 
          focus:ring-primary-500 
          focus:ring-opacity-50
          ${error ? 'border-red focus:border-red focus:ring-red' : 'border-primary-300'}`}
          {...rest}
        />
        <p className="text-red h-[21px] mb-1">{error?.message}</p>
      </div>
    );
  }

  return (
    <div className={`mt-1 sm:col-span-1 md:col-span-${col}`}>
      <label
        htmlFor={id}
        className="block text-md font-semibold text-pretty"
      >
        {label}
        {required && <span className="text-red ml-1">*</span>}
      </label>
      <input
        type={type ?? 'text'}
        id={id}
        {...register(id)}
        className={`
        bg-slate-100
        w-full p-4 mt-2 
        border rounded-md
        outline-none
        border-primary-300 
        focus:ring-1 
        focus:border-primary-500 
        focus:ring-primary-500 
        focus:ring-opacity-50
        ${error ? 'border-red focus:border-red focus:ring-red' : 'border-primary-300'}`}
        {...rest}
      />
      <p className="text-red h-[21px] mb-1">{error?.message}</p>
    </div>
  );
};

export default Input;
