export const stepsEnum = Object.freeze({
  GENERAL: 'General',
  HEALTH_DETAILS: 'Detalles de Salud',
  MEDICAL_HISTORY: 'Historial Médico',
  SURGICAL_HISTORY: 'Historial Quirúrgico',
  GENERAL_HEALTH: 'Salud General',
  REFERENCES: 'Referencias',
});
export const stepsEnumEn = Object.freeze({
  GENERAL: 'General',
  HEALTH_DETAILS: 'Health Details',
  MEDICAL_HISTORY: 'Medical History',
  SURGICAL_HISTORY: 'Surgical History',
  GENERAL_HEALTH: 'General Health',
  REFERENCES: 'References',
});

export const steps = [
  {
    text_es: stepsEnum.GENERAL,
    text_en: stepsEnumEn.GENERAL,
    icon: null,
  },
  {
    text_es: stepsEnum.HEALTH_DETAILS,
    text_en: stepsEnumEn.HEALTH_DETAILS,
    icon: null,
  },
  {
    text_es: stepsEnum.MEDICAL_HISTORY,
    text_en: stepsEnumEn.MEDICAL_HISTORY,
    icon: null,
  },
  {
    text_es: stepsEnum.SURGICAL_HISTORY,
    text_en: stepsEnumEn.SURGICAL_HISTORY,
    icon: null,
  },
  {
    text_es: stepsEnum.GENERAL_HEALTH,
    text_en: stepsEnumEn.GENERAL_HEALTH,
    icon: null,
  },
  {
    text_es: stepsEnum.REFERENCES,
    text_en: stepsEnumEn.REFERENCES,
    icon: null,
  },
];

const defaultOption = {
  value: undefined,
  label_es: 'Seleccione una opción',
  label_en: 'Select an option',
};

export const trueFalseOptions = [
  defaultOption,
  { value: false, label: 'No' },
  { value: true, label_es: 'Sí', label_en: 'Yes' },
];

export const genderOptions = [
  defaultOption,
  { value: 'M', label_es: 'Masculino', label_en: 'Male' },
  { value: 'F', label_es: 'Femenino', label_en: 'Female' },
];

export const servicesEnum = Object.freeze({
  DENTAL: '7',
  BARIATRIC_SURGERY: '1',
  ONCOLOGY: '3',
  PLASTIC_SURGERY: '5',
  UROLOGY: '9',
});

export const urologyFemaleProceduresOptions = [
  defaultOption,
  {
    value: '4',
    label_es: 'Tratamiento para incontinencia urinaria',
    label_en: 'Urinary Incontinence Treatment',
  },
];

export const plasticSurgeryOptions = [
  defaultOption,
  {
    value: '1',
    label_es: 'Lipoeescultura',
    label_en: 'Liposculpture',
  },
  {
    value: '2',
    label_es: 'Aumento de Busto',
    label_en: 'Breast Augmentation',
  },
  {
    value: '3',
    label_en: 'Levantamiento de Busto',
    label_es: 'Breast Lifting',
  },
  {
    value: '4',
    label_es: 'Abdominoplastia (Lipectomía)',
    label_en: 'Abdominoplasty (Lipectomy)',
  },
  {
    value: '5',
    label_es: 'Levantamiento de Glúteo',
    label_en: 'Gluteal Lifting',
  },
  {
    value: '6',
    label_es: 'Estiramiento Facial (Ritidectomía)',
    label_en: 'Facial Stretching (Rhytidectomy)',
  },
  {
    value: '7',
    label_es: 'Blefaroplastía',
    label_en: 'Blepharoplasty',
  },
  {
    value: '8',
    label_es: 'Braquioplastía',
    label_en: 'Brachioplasty',
  },
  {
    value: '9',
    label_es: 'Lipo Papada',
    label_en: 'Chin Lipo',
  },
  {
    value: '10',
    label_es: 'Rinoplastia',
    label_en: 'Rhinoplasty',
  },
  {
    value: '11',
    label_es: 'Cruroplastía',
    label_en: 'Thigh Lift',
  },
  {
    value: '12',
    label: 'Botox',
  },
  {
    value: '13',
    label_es: 'Ácido Hialurónico',
    label_en: 'Hyaluronic Acid',
  },
  {
    value: '14',
    label_es: 'Exfoliación Química',
    label_en: 'Chemical Exfoliation',
  },
  {
    value: '15',
    label: 'Mommy Makeover',
  },
];

export const procedureOptions = [
  {
    value: 1,
    label_es: 'Tratamiento para disfunción eréctil',
    label_en: 'Erectile Dysfunction Treatment',
  },
  {
    value: 2,
    label_es: 'Vaporización',
    label_en: 'Vaporization',
  },
  {
    value: 3,
    label_es: 'Vaso Anostomosis',
    label_en: 'Anostomosis Vessel',
  },
];

export const extraPlasticSurgeryOptions = [
  defaultOption,
  {
    value: '1',
    label: 'Fillers',
  },
  {
    value: '2',
    label: 'Botox',
  },
  {
    value: '3',
    label: 'Extra Lipo',
  },
  {
    value: '4',
    label_es: 'Otro',
    label_en: 'Other',
  },
];

export const bariatricSurgeryOptions = [
  defaultOption,
  {
    value: '1',
    label_es: 'Manga Gástrica',
    label_en: 'Gastric Sleeve',
  },
  {
    value: '2',
    label_es: 'Cirugía de Revisión',
    label_en: 'Revision Surgery',
  },
  {
    value: '3',
    label_es: 'Bypass Gástrico',
    label_en: 'Gastric Bypass',
  },
  {
    value: '4',
    label_es: 'Balón Gástrico',
    label_en: 'Gastric Balloon',
  },
];

export const bariatricSurgeryPackages = [
  defaultOption,
  {
    value: '1',
    label_es: 'Básico',
    label_en: 'Basic',
  },
  {
    value: '2',
    label: 'Premium',
  },
  {
    value: '3',
    label: 'Premium Plus',
  },
];

export const bariatricLessSurgeryPackages = [
  defaultOption,
  {
    value: '1',
    label_es: 'Básico',
    label_en: 'Basic',
  },
  {
    value: '2',
    label: 'Plus',
  },
];

export const goodRegularBadOptions = [
  defaultOption,
  {
    value: '1',
    label_es: 'Bueno',
    label_en: 'Good',
  },
  {
    value: '2',
    label_es: 'Regular',
    label_en: 'Regular',
  },
  {
    value: '3',
    label_es: 'Malo',
    label_en: 'Bad',
  },
];

export const sesibilityOptions = [
  defaultOption,
  {
    value: '1',
    label_es: 'Frio',
    label_en: 'Cold',
  },
  {
    value: '2',
    label_es: 'Calor',
    label_en: 'Hot',
  },
  {
    value: '3',
    label_es: 'Dulce',
    label_en: 'Sweet',
  },
];

export const metricSystemOptions = [
  defaultOption,
  {
    value: '1',
    label_es: 'Métrico (Kg, cm)',
    label_en: 'Metric (Kg, cm)',
  },
  {
    value: '2',
    label: 'Imperial (Lb, ft)',
  },
];

export const sangradoOptions = [
  defaultOption,
  {
    value: '1',
    label_es: 'Normal',
    label_en: 'Normal',
  },
  {
    value: '2',
    label_es: 'Ligero',
    label_en: 'Light',
  },
  {
    value: '3',
    label_es: 'Abundante',
    label_en: 'Heavy',
  },
  {
    value: '4',
    label_es: 'Irregular',
    label_en: 'Irregular',
  },
];

export const oncologyImageStudiesOptions = [
  defaultOption,
  {
    value: '1',
    label: 'No',
  },
  {
    value: '2',
    label_es: 'Tomografía computarizada',
    label_en: 'CT scan',
  },
  {
    value: '3',
    label_es: 'Resonancia Magnética',
    label_en: 'Magnetic Resonance (MRI)',
  },
  {
    value: '4',
    label_es: 'TC PET',
    label_en: 'PET CT',
  },
  {
    value: '5',
    label_es: 'Otros',
    label_en: 'Others',
  },
];

export const proceduesEnum = Object.freeze({
  CIRCUMCISION: 1,
  VASECTOMY_REVERSAL: 2,
  FACIAL_STRETCHING: 27,
  HYDROCELE: 4,
  VASECTOMY: 5,
  VARICOCELE: 6,
  TRANSURETRAL_RESECTION_OF_BLADDER_TUMORS: 7,
  TESTICULAR_TUMORS: 8,
  GENITAL_WARTS_REMOVAL: 9,
  TRANSURETRAL_RESECTION_OF_THE_PROSTATE: 10,
  LASER_CYSTO_LITHOTRIPSY_OF_BLADDER_STONES: 11,
  LASER_URETEROSCOPY: 12,
  PERCUTANEOUS_NEPHROLITHOTOMY: 13,
  LASER_KIDNEY_URETEROSCOPY: 14,
  CYSTOSCOPY: 15,
  COLPOPLASTY: 16,
  NEPHRECTOMY: 17,
  TREATMENT_FOR_ERECTILE_DYSFUNCTION: 18,
  GREEN_LIGHT_LASER_SURGERY: 19,
  URINARY_INCONTINENCE_SURGERY: 20,
  MOMMY_MAKEOVER: 21,
  LIPOSCULPTURE: 22,
  BREAST_AUGMENTATION: 23,
  BREAST_LIFTING: 24,
  ABDOMINOPLASTY: 25,
  GLUTEAL_LIFT: 26,
  GASTRIC_BALLOON: 28,
  DUODENAL_SWITCH: 29,
  BLEPHAROPLASTY: 30,
  CHIN_LIPO: 31,
  BRACHIOPLASTY: 32,
  RHINOPLASTY: 33,
  THIGH_LIFT: 34,
  BOTOX: 35,
  HYALURONIC_ACID: 36,
  GASTRIC_SLEEVE: 37,
  CHEMICAL_EXFOLIATION: 38,
  REVISION_SURGERY: 39,
  GASTRIC_BYPASS: 40,
  CLEANING: 41,
  ROOT_CANAL_TREATMENT: 42,
  EXTRACTIONS: 43,
  IMPLANT_PROCEDURES: 44,
  SADI: 45,
  CROWNS_VENEERS: 46,
  FILLINGS: 47,
  DENTURES: 48,
});
