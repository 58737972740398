import { useEffect, useState } from 'react';
import Title from 'components/Title';
import Steps from 'components/Steps';
import { steps, stepsEnum, stepsEnumEn } from 'utils/enums';
import FormularioGeneral from 'components/Forms/FormularioGeneral';
import { applicationSchema } from 'utils/validations/generalFormSchema';
import StepsControls from 'components/SepsControls';
import DetallesDeSalud from 'components/Forms/DetallesDeSalud';
import { postClient } from 'utils/api/requests';
import { useTranslation } from 'react-i18next';
import { getCurrentLanguage } from 'components/ToggleLanguege';
import { healthDetailsFormSchema } from 'utils/validations/HealthDetailsFormSchema';
import HistorialMedico from 'components/Forms/HistorialMedico';
import { medicalHistorySchema } from 'utils/validations/MedicalHistorySchema';
import { surgeryHistorySchema } from 'utils/validations/surgeryHistorySchema';
import { HistorialQururgico } from 'components/Forms/HistorialQururgico';
import { generalHealthSchema } from 'utils/validations/generalHealthSchema';
import SaludGeneral from 'components/Forms/SaludGeneral';
import { Navbar } from 'components/Navbar';
import Referencias from 'components/Forms/Referencias';
import ModalConfirm from 'components/ModalConfirm';
import { toast } from 'react-toastify';
import Footer from 'components/Footer';

const App = () => {
  const [t] = useTranslation('global');
  const lang = getCurrentLanguage();
  const _stepsEnum = lang === 'es' ? stepsEnum : stepsEnumEn;
  const [step, setStep] = useState(_stepsEnum.GENERAL);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  useEffect(() => {
    const isSpanish = lang === 'es';
    if (!isSpanish) {
      document.title = 'Submit Your Information | JL Prado Surgical Center';
      if (step === stepsEnum.GENERAL) {
        setStep(stepsEnumEn.GENERAL);
      }
      if (step === stepsEnum.HEALTH_DETAILS) {
        setStep(stepsEnumEn.HEALTH_DETAILS);
      }
      if (step === stepsEnum.MEDICAL_HISTORY) {
        setStep(stepsEnumEn.MEDICAL_HISTORY);
      }
      if (step === stepsEnum.SURGICAL_HISTORY) {
        setStep(stepsEnumEn.SURGICAL_HISTORY);
      }
      if (step === stepsEnum.GENERAL_HEALTH) {
        setStep(stepsEnumEn.GENERAL_HEALTH);
      }
      if (step === stepsEnum.REFERENCES) {
        setStep(stepsEnumEn.REFERENCES);
      }
    } else {
      document.title = 'Formulario de Citas | JL Prado Surgical Center';
      if (step === stepsEnumEn.GENERAL) {
        setStep(stepsEnum.GENERAL);
      }
      if (step === stepsEnumEn.HEALTH_DETAILS) {
        setStep(stepsEnum.HEALTH_DETAILS);
      }
      if (step === stepsEnumEn.MEDICAL_HISTORY) {
        setStep(stepsEnum.MEDICAL_HISTORY);
      }
      if (step === stepsEnumEn.SURGICAL_HISTORY) {
        setStep(stepsEnum.SURGICAL_HISTORY);
      }
      if (step === stepsEnumEn.GENERAL_HEALTH) {
        setStep(stepsEnum.GENERAL_HEALTH);
      }
      if (step === stepsEnumEn.REFERENCES) {
        setStep(stepsEnum.REFERENCES);
      }
    }
  }, [lang]);

  useEffect(() => {
    const appId = localStorage.getItem('APPLICATION_ID');
    const clientEmail = localStorage.getItem('EMAIL_SENT_TO');
    if (appId) {
      toast.success(`${t('toastThanks')}${clientEmail}`, {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    setTimeout(() => {
      localStorage.removeItem('APPLICATION_ID');
      localStorage.removeItem('EMAIL_SENT_TO');
    }, 3000);
  }, []);

  const handleBackStep = () => {
    const currentStepIndex = steps.findIndex((s) => s[`text_${lang}`] === step);
    const backStep = steps[currentStepIndex - 1]?.[`text_${lang}`];
    if (backStep) {
      setStep(backStep);
    }
  };

  const handleNextStep = async (nextStepIndex) => {
    const currentStepIndex = steps.findIndex((s) => s[`text_${lang}`] === step);
    const nextStep = steps[currentStepIndex + nextStepIndex]?.[`text_${lang}`];
    if (!nextStep) {
      setVisibleConfirm(true);
    }

    if (nextStep === _stepsEnum.HEALTH_DETAILS) {
      const formValues = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES')) || {};
      const { success } = applicationSchema().safeParse(formValues);
      if (success) {
        const clientId = localStorage.getItem('CLIENT_ID');
        if (!clientId) {
          const created = await postClient(formValues);
          if (!created) return;
        }
        setStep(nextStep);
      }
    }

    if (nextStep === _stepsEnum.MEDICAL_HISTORY) {
      const formValuesHealth = JSON.parse(localStorage.getItem('DETALLES_DE_SALUD_FORM_VALUES')) || {};
      const { success } = healthDetailsFormSchema().safeParse(formValuesHealth);
      if (success) {
        setStep(nextStep);
      }
    }
    if (nextStep === _stepsEnum.SURGICAL_HISTORY) {
      const formValuesMedical = JSON.parse(localStorage.getItem('FORM_VALUES_HISTORIAL_MEDICO')) || {};
      const { success } = medicalHistorySchema().safeParse(formValuesMedical);
      if (success) {
        setStep(nextStep);
      }
    }
    if (nextStep === _stepsEnum.GENERAL_HEALTH) {
      const formValuesSurgical = JSON.parse(localStorage.getItem('FORM_VALUES_HISTORIAL_QUIRURGICO')) || {};
      const { success } = surgeryHistorySchema().safeParse(formValuesSurgical);
      if (success) {
        setStep(nextStep);
      }
    }
    if (nextStep === _stepsEnum.REFERENCES) {
      const formValuesSaludGeneral = JSON.parse(localStorage.getItem('FORM_VALUES_SALUD_GENERAL')) || {};
      const { success } = generalHealthSchema().safeParse(formValuesSaludGeneral);
      if (success) {
        setStep(nextStep);
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="w-full px-4 mx-auto xs:max-w-[576px] sm:max-w-[786px] md:max-w-[992px] lg:sm:max-w-[1200px]">
        <div className="flex flex-wrap mx-4">
          <div className="flex-[0_0_100%]">
            {/* <ToggleLanguege /> */}
            <main className="block ">
              <div className="mt-6">
                <Title text={t('aplicationForm.title')} />
              </div>
              <div className="mt-10 md:px-10">
                <Title
                  text={t('aplicationForm.subtitle')}
                  level={2}
                />
              </div>
              <div className="mt-10">
                <div className="md:px-10">
                  <Title
                    text={t('aplicationForm.headText')}
                    level={4}
                  />
                </div>
                <p className="md:px-10 ">
                  {t('aplicationForm.paragraphOne')}
                </p>
                <p className="mt-6 md:px-10">
                  {t('aplicationForm.paragraphTwo')}
                </p>
              </div>
              <Steps currentStep={step} />
              <div className="mt-10 md:px-10">
                {(step === stepsEnum.GENERAL || step === stepsEnumEn.GENERAL) && (
                <FormularioGeneral>
                  <StepsControls
                    step={step}
                    handleBackStep={handleBackStep}
                    handleNextStep={handleNextStep}
                  />
                </FormularioGeneral>
                )}
                {(step === _stepsEnum.HEALTH_DETAILS || step === stepsEnumEn.HEALTH_DETAILS) && (
                <DetallesDeSalud>
                  <StepsControls
                    step={step}
                    handleBackStep={handleBackStep}
                    handleNextStep={handleNextStep}
                  />
                </DetallesDeSalud>
                )}
                {(step === stepsEnum.MEDICAL_HISTORY || step === stepsEnumEn.MEDICAL_HISTORY) && (
                <HistorialMedico>
                  <StepsControls
                    step={step}
                    handleBackStep={handleBackStep}
                    handleNextStep={handleNextStep}
                  />
                </HistorialMedico>
                )}
                {(step === stepsEnum.SURGICAL_HISTORY || step === stepsEnumEn.SURGICAL_HISTORY) && (
                <HistorialQururgico>
                  <StepsControls
                    step={step}
                    handleBackStep={handleBackStep}
                    handleNextStep={handleNextStep}
                  />
                </HistorialQururgico>
                )}
                {(step === stepsEnum.GENERAL_HEALTH || step === stepsEnumEn.GENERAL_HEALTH) && (
                <SaludGeneral>
                  <StepsControls
                    step={step}
                    handleBackStep={handleBackStep}
                    handleNextStep={handleNextStep}
                  />
                </SaludGeneral>
                )}
                {(step === stepsEnum.REFERENCES || step === stepsEnumEn.REFERENCES) && (
                  <Referencias>
                    <StepsControls
                      step={step}
                      handleBackStep={handleBackStep}
                      handleNextStep={handleNextStep}
                    />
                  </Referencias>
                )}
              </div>
            </main>
          </div>
        </div>
      </div>
      <Footer />
      <ModalConfirm visible={visibleConfirm} setVisible={setVisibleConfirm} />
    </>
  );
};

export default App;
