import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { FilesContext } from 'contexts/Files';
import Upload from './icons/Upload';
import Title from './Title';
import Close from './icons/Close';

const MAX_FILE_SIZE = 5000000;

const ACCEPTED_FILES = '.jpg,.jpeg,.png,.webp';

const Dragger = ({
  id,
  label,
  register,
  required,
  error,
  resetField,
  clearErrors,
  ...rest
}) => {
  const { setFiles } = useContext(FilesContext);
  const { t } = useTranslation('global');
  const [image, setImage] = useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        return;
      }
      if (!file.type.includes('image')) {
        return;
      }
      setImage(Object.assign(file, { preview: URL.createObjectURL(file) }));
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    name: id,
    accept: ACCEPTED_FILES,
    maxSize: MAX_FILE_SIZE,
  });

  const { ref, name, onBlur, onChange: registerOnChange } = register(id);
  const { multiple, onChange: inpOnChange, onClick, tabIndex, type } = getInputProps();

  useEffect(() => {
    if (!image && typeof resetField === 'function') {
      resetField(id);
    } else if (image) {
      clearErrors(id);
    }
    setFiles((prev) => ({ ...prev, [id]: image }));
  }, [image]);

  return (
    <>
      <div className={`${image ? '' : 'hidden'} flex flex-col justify-center relative`}>
        <div className="m-5">
          <Title
            text={label}
            level={4}
          />
        </div>
        <img
          src={image?.preview}
          alt={`preview-${id}`}
          className="h-96 object-contain rounded-xl w-full mt-2"
        />
        <button
          aria-label="delete"
          type="button"
          onClick={() => {
            resetField(id);
            setImage(null);
          }}
          className="absolute top-2 right-2 bg-white rounded-full p-2"
        >
          <Close />
        </button>
        <p className="text-red h-[21px] mb-1">{error?.message}</p>
      </div>
      <div
        {...getRootProps()}
        className={`mt-1 ${!image ? '' : 'hidden'}`}
      >
        <label
          htmlFor={id}
          className="block text-sm font-semibold"
        >
          <Title
            text={
              <>
                {label}
                {required && <span className="text-red ml-1">*</span>}
              </>
            }
            level={4}
          />
          <div
            className={`border-dashed border-2 h-96 flex ${
              error?.message ? 'border-red' : 'border-gray-300'
            } rounded-md p-4 mt-2 outline-none focus:ring-1 focus:ring-primary-500`}
          >
            <input
              id={id}
              className="hidden"
              ref={ref}
              name={name}
              onBlur={onBlur}
              onChange={(e) => {
                registerOnChange(e);
                inpOnChange(e);
              }}
              onClick={onClick}
              tabIndex={tabIndex}
              type={type}
              accept="image/png, image/gif, image/jpeg, image/jpg"
              multiple={multiple}
              {...rest}
            />
            {!image && !isDragActive && (
            <p className="flex text-sm text-gray-500 justify-center flex-col w-full items-center text-center">
              <Upload className={error?.message ? 'text-red' : ''} />
              {t('dragAndDrop')}
            </p>
            )}
            {!image && isDragActive && (
            <p className="flex text-sm text-gray-500 justify-center flex-col w-full items-center">
              <Upload />
              {t('drgging')}
            </p>
            )}
          </div>
        </label>
        <p className="text-red h-[21px] mb-1">{error?.message}</p>
      </div>
    </>
  );
};

export default Dragger;
