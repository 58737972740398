import ArrowDown from 'components/icons/ArrowDown';
import Hamburger from 'components/icons/Hamburger';
import { getCurrentLanguage } from 'components/ToggleLanguege';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Navbar = () => {
  const lang = getCurrentLanguage();
  const { i18n, t } = useTranslation('navbar');

  const changeLanguage = (lng) => {
    localStorage.setItem('i18nextLng', lng);
    i18n.changeLanguage(lng);
  };

  return (
    (
      <nav className="bg-white border border-b-primary-100 border-y-2">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <a href="https://jlpradosc.com/inicio/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img
              src="/logo512.png"
              className="h-24"
              alt="Flowbite Logo"
            />
          </a>
          <button
            data-collapse-toggle="navbar-multi-level"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg lg:hidden hover:bg-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-200 "
            aria-controls="navbar-multi-level"
            aria-expanded="false"
            onTouchStart={() => {
              document.getElementById('navbar-multi-level').classList.toggle('hidden');
            }}
          >
            <span className="sr-only">Open main menu</span>
            <Hamburger />
          </button>
          <div className="hidden w-full lg:block md:w-auto" id="navbar-multi-level">
            <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
              <li>
                <a
                  href="https://jlpradosc.com/inicio/"
                  className="block py-2 px-3 text-primary-100 rounded md:bg-transparent md:p-0 md:hover:text-primary-400"
                  aria-current="page"
                >
                  {t('home')}
                </a>
              </li>
              <li>
                <button
                  type="button"
                  id="NosotrosNavbarLink"
                  data-dropdown-toggle="dropdownNosotros"
                  className="flex items-center justify-between w-full py-2 px-3 text-primary-100 hover:bg-primary-200 hover:text-white md:hover:bg-transparent md:border-0 md:hover:text-primary-400 md:p-0 md:w-auto "
                  onTouchStart={() => {
                    document.getElementById('dropdownNosotros').classList.toggle('hidden');
                  }}
                >
                  {t('aboutUs')}
                  <ArrowDown />
                </button>
                {/* Dropdown menu */}
                <div
                  id="dropdownNosotros"
                  className="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow"
                >
                  <ul
                    className="py-2 text-sm text-gray-700"
                    aria-labelledby="dropdownLargeButton"
                  >
                    <li>
                      <a
                        href="https://jlpradosc.com/history/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        {t('history')}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jlpradosc.com/team/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        {t('ourTeam')}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jlpradosc.com/our-facilities/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        {t('facilities')}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <button
                  type="button"
                  id="dropdownNavbarLink"
                  data-dropdown-toggle="dropdownServicios"
                  className="flex items-center justify-between w-full py-2 px-3 text-primary-100 hover:bg-primary-200 hover:text-white md:hover:bg-transparent md:border-0 md:hover:text-primary-400 md:p-0 md:w-auto "
                  onTouchStart={() => {
                    document.getElementById('dropdownServicios').classList.toggle('hidden');
                  }}
                >
                  {t('services')}
                  <ArrowDown />
                </button>
                {/* Dropdown menu */}
                <div
                  id="dropdownServicios"
                  className="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow"
                >
                  <ul
                    className="py-2 text-sm text-gray-700"
                    aria-labelledby="dropdownLargeButton"
                  >
                    <li>
                      <a
                        href="https://jlpradosc.com/a-slimmer-me/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        {t('bariatricSurgery')}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jlpradosc.com/a-beautiful-me/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        {t('plasticSurgery')}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jlpradosc.com/a-better-me-urology/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        {t('urology')}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jlpradosc.com/a-smiling-me/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        A Smiling Me - Dental
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jlpradosc.com/my-healed-self/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        {t('oncology')}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jlpradosc.com/a-younger-me-aesthetic-medicine/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        {t('aestheticsMedicine')}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jlpradosc.com/a-scope-me-endoscopy-colonoscopy/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        {t('endoscopy')}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jlpradosc.com/general-surgery/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        {t('generalSurgery')}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <button
                  type="button"
                  id="dropdownNavbarLink"
                  data-dropdown-toggle="dropdownTestimonios"
                  className="flex items-center justify-between w-full py-2 px-3 text-primary-100 hover:bg-primary-200 hover:text-white md:hover:bg-transparent md:border-0 md:hover:text-primary-400 md:p-0 md:w-auto "
                  onTouchStart={() => {
                    document.getElementById('dropdownTestimonios').classList.toggle('hidden');
                  }}
                >
                  {t('testimonials')}
                  <ArrowDown />
                </button>
                {/* Dropdown menu */}
                <div
                  id="dropdownTestimonios"
                  className="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow"
                >
                  <ul
                    className="py-2 text-sm text-gray-700"
                    aria-labelledby="dropdownLargeButton"
                  >
                    <li aria-labelledby="dropdownNavbarLink">
                      <button
                        id="doubleDropdownButton"
                        data-dropdown-toggle="doubleDropdownVideos"
                        data-dropdown-placement="right-start"
                        type="button"
                        className="flex items-center justify-between w-full px-4 py-2 hover:bg-primary-200 hover:text-white"
                        onTouchStart={() => {
                          document.getElementById('doubleDropdownVideos').classList.toggle('hidden');
                        }}
                      >
                        {t('videos')}
                        <ArrowDown />
                      </button>
                      <div
                        id="doubleDropdownVideos"
                        className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow"
                      >
                        <ul
                          className="py-2 text-sm text-gray-700"
                          aria-labelledby="doubleDropdownButton"
                        >
                          <li>
                            <a
                              href="https://jlpradosc.com/a-slimmer-me-patients/"
                              className="block px-4 py-2 hover:bg-primary-200 hover:text-white "
                            >
                              {t('bariatricPatients')}
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://jlpradosc.com/plastic-surgery-patients/"
                              className="block px-4 py-2 hover:bg-primary-200 hover:text-white "
                            >
                              {t('plasticPatients')}
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://jlpradosc.com/a-smiling-me-patients/"
                              className="block px-4 py-2 hover:bg-primary-200 hover:text-white "
                            >
                              {t('dentalPatients')}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li aria-labelledby="dropdownNavbarLink">
                      <button
                        id="doubleDropdownButton"
                        data-dropdown-toggle="doubleDropdownAntesDespues"
                        data-dropdown-placement="right-start"
                        type="button"
                        className="flex items-center justify-between w-full px-4 py-2 hover:bg-primary-200 hover:text-white"
                        onTouchStart={() => {
                          document.getElementById('doubleDropdownAntesDespues').classList.toggle('hidden');
                        }}
                      >
                        {t('beforeAfter')}
                        <ArrowDown />
                      </button>
                      <div
                        id="doubleDropdownAntesDespues"
                        className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow"
                      >
                        <ul
                          className="py-2 text-sm text-gray-700"
                          aria-labelledby="doubleDropdownButton"
                        >
                          <li>
                            <a
                              href="https://jlpradosc.com/before-after-bariatrics/"
                              className="block px-4 py-2 hover:bg-primary-200 hover:text-white "
                            >
                              {t('bariatricPatients')}
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://jlpradosc.com/before-and-after-plastic-surgery/"
                              className="block px-4 py-2 hover:bg-primary-200 hover:text-white "
                            >
                              {t('plasticPatients')}
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://jlpradosc.com/before-and-after-dental/"
                              className="block px-4 py-2 hover:bg-primary-200 hover:text-white "
                            >
                              {t('dentalPatients')}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                  <div className="py-1">
                    <a
                      href="https://jlpradosc.com/a-slimmer-me-t-shirt/"
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-primary-200 hover:text-white"
                    >
                      {t('tshirt')}
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <button
                  type="button"
                  id="dropdownNavbarLink"
                  data-dropdown-toggle="dropdownFAQ"
                  className="flex items-center justify-between w-full py-2 px-3 text-primary-100 hover:bg-primary-200 hover:text-white md:hover:bg-transparent md:border-0 md:hover:text-primary-400 md:p-0 md:w-auto "
                  onTouchStart={() => {
                    document.getElementById('dropdownFAQ').classList.toggle('hidden');
                  }}
                >
                  FAQ
                  <ArrowDown />
                </button>
                {/* Dropdown menu */}
                <div
                  id="dropdownFAQ"
                  className="z-10 hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow"
                >
                  <ul
                    className="py-2 text-sm text-gray-700"
                    aria-labelledby="dropdownLargeButton"
                  >
                    <li>
                      <a
                        href="https://jlpradosc.com/informative-videos/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        {t('informativeVideos')}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jlpradosc.com/faqs/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        {t('questions')}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://jlpradosc.com/blog/"
                        className="block px-4 py-2 hover:bg-primary-200 hover:text-white"
                      >
                        Blog
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a
                  href="https://jlpradosc.com/packages/"
                  className="block py-2 px-3 text-primary-100 rounded md:bg-transparent md:p-0 md:hover:text-primary-400"
                  aria-current="page"
                >
                  {t('packages')}
                </a>
              </li>
              <li>
                <a
                  href="https://jlpradosc.com/financing/"
                  className="block py-2 px-3 text-primary-100 rounded md:bg-transparent md:p-0 md:hover:text-primary-400"
                  aria-current="page"
                >
                  {t('financing')}
                </a>
              </li>
              <li>
                <a
                  href="https://jlpradosc.com/contact-us/"
                  className="block py-2 px-3 text-primary-100 rounded md:bg-transparent md:p-0 md:hover:text-primary-400"
                  aria-current="page"
                >
                  {t('contact')}
                </a>
              </li>
              <li>
                {lang === 'es' ? (
                  <button
                    type="button"
                    className="block py-2 px-3 text-primary-100 rounded md:bg-transparent md:p-0 md:hover:text-primary-400"
                    aria-current="page"
                    onClick={() => {
                      changeLanguage('en');
                    }}
                  >
                    English
                  </button>
                ) : (
                  <button
                    type="button"
                    className="block py-2 px-3 text-primary-100 rounded md:bg-transparent md:p-0 md:hover:text-primary-400"
                    aria-current="page"
                    onClick={() => {
                      changeLanguage('es');
                    }}
                  >
                    Español
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    )
  );
};
