import Container from 'components/Container';
import Input from 'components/Input';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const FORM_NAME = 'REFERENCIAS';

const Referencias = ({ children }) => {
  const { t } = useTranslation('global');
  const {
    handleSubmit,
    register,
    formState: { error },
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });

  useEffect(() => {
    const formValues = JSON.parse(localStorage.getItem(FORM_NAME));
    if (formValues) {
      const keys = Object.keys(formValues);
      keys.forEach((key) => {
        setValue(key, formValues[key]);
      });
    }
  }, []);

  const formValues = watch();

  useEffect(() => {
    localStorage.setItem(FORM_NAME, JSON.stringify(formValues));
  }, [formValues]);

  return (
    <form
      onSubmit={handleSubmit(() => {})}
      className="mb-14"
    >
      <div className="flex justify-center">
        <ul className="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg">
          <li className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center ps-3">
              <label
                htmlFor="google-checkbox"
                className="w-full py-3 ms-2 text-sm font-medium "
              >
                <input
                  id="google-checkbox"
                  type="checkbox"
                  {...register('google')}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-3"
                />
                Google
              </label>
            </div>
          </li>
          <li className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center ps-3">
              <label
                htmlFor="facebook-checkbox"
                className="w-full py-3 ms-2 text-sm font-medium "
              >
                <input
                  id="facebook-checkbox"
                  type="checkbox"
                  {...register('facebook')}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-3"
                />
                Facebook
              </label>
            </div>
          </li>
          <li className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center ps-3">
              <label
                htmlFor="youtube-checkbox"
                className="w-full py-3 ms-2 text-sm font-medium "
              >
                <input
                  id="youtube-checkbox"
                  type="checkbox"
                  {...register('youtube')}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-3"
                />
                Youtube
              </label>
            </div>
          </li>
          <li className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center ps-3">
              <label
                htmlFor="vimeo-checkbox"
                className="w-full py-3 ms-2 text-sm font-medium "
              >
                <input
                  id="vimeo-checkbox"
                  type="checkbox"
                  {...register('vimeo')}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-3"
                />
                Vimeo
              </label>
            </div>
          </li>
          <li className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center ps-3">
              <label
                htmlFor="twitter-checkbox"
                className="w-full py-3 ms-2 text-sm font-medium "
              >
                <input
                  id="twitter-checkbox"
                  type="checkbox"
                  {...register('twitter')}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-3"
                />
                Twitter / X
              </label>
            </div>
          </li>
          <li className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center ps-3">
              <label
                htmlFor="web-checkbox"
                className="w-full py-3 ms-2 text-sm font-medium "
              >
                <input
                  id="web-checkbox"
                  type="checkbox"
                  {...register('web')}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-3"
                />
                {t('web')}
              </label>
            </div>
          </li>
          <li className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center ps-3">
              <label
                htmlFor="instagram-checkbox"
                className="w-full py-3 ms-2 text-sm font-medium "
              >
                <input
                  id="instagram-checkbox"
                  type="checkbox"
                  {...register('instagram')}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-3"
                />
                Instagram
              </label>
            </div>
          </li>
          <li className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center ps-3">
              <label
                htmlFor="radio-checkbox"
                className="w-full py-3 ms-2 text-sm font-medium "
              >
                <input
                  id="radio-checkbox"
                  type="checkbox"
                  {...register('radio')}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-3"
                />
                Radio
              </label>
            </div>
          </li>
          <li className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center ps-3">
              <label
                htmlFor="email-checkbox"
                className="w-full py-3 ms-2 text-sm font-medium "
              >
                <input
                  id="email-checkbox"
                  type="checkbox"
                  {...register('mail')}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-3"
                />
                Email
              </label>
            </div>
          </li>
          <li className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center ps-3">
              <label
                htmlFor="friend-checkbox"
                className="w-full py-3 ms-2 text-sm font-medium "
              >
                <input
                  id="friend-checkbox"
                  type="checkbox"
                  {...register('friend')}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-3"
                />
                {t('friendReference')}
              </label>
            </div>
          </li>
          <li className="w-full border-b border-gray-200 rounded-t-lg ">
            <div className="flex items-center ps-3">
              <label
                htmlFor="other-checkbox"
                className="w-full py-3 ms-2 text-sm font-medium "
              >
                <input
                  id="other-checkbox"
                  type="checkbox"
                  {...register('other')}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 mr-3"
                />
                {t('other')}
              </label>
            </div>
          </li>
        </ul>
      </div>
      <Container>
        {formValues?.other && (
        <Input
          label={t('referenceOtro')}
          id="referenceOtro"
          type="text"
          register={register}
          error={error?.referenceOtro}
        />
        )}
        {formValues?.friend && (
        <Input
          label={t('nombreAmigo')}
          id="nombreAmigo"
          type="text"
          register={register}
          error={error?.nombreAmigo}
        />
        )}
      </Container>
      {children}
    </form>
  );
};

export default Referencias;
