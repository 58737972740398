/* eslint-disable no-param-reassign */
import axios from 'axios';
import { HOST, SCHEME, PATH } from 'utils/env';

export const baseURL = `${SCHEME}://${HOST}/${PATH}`;

const defaultOptions = {
  baseURL,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
  },
};

const http = axios.create(defaultOptions);

const requestInterceptor = async (options) => {
  if (options.url.at(-1) !== '/') {
    options.url = `${options.url}/`;
  }
  return options;
};

http.interceptors.request.use(requestInterceptor);

export default http;
