import { zodResolver } from '@hookform/resolvers/zod';
import Container from 'components/Container';
import Minus from 'components/icons/Minus';
import Plus from 'components/icons/Plus';
import Input from 'components/Input';
import Select from 'components/Select';
import Title from 'components/Title';
import { useEffect } from 'react';
import { useFieldArray, useForm, FormProvider } from 'react-hook-form';
import { trueFalseOptions } from 'utils/enums';
import { generalHealthSchema } from 'utils/validations/generalHealthSchema';
import { useTranslation } from 'react-i18next';

const FORM_NAME = 'FORM_VALUES_SALUD_GENERAL';
const FORM_GENERAL_NAME = 'FORM_GENERAL_VALUES';

const SaludGeneral = ({ children }) => {
  const { t } = useTranslation('global');
  const methods = useForm({
    resolver: zodResolver(generalHealthSchema()),
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldUnregister: true,
  });

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    control,
    setValue,
    setFocus,
  } = methods;

  const {
    fields,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'exercises',
  });

  const formValues = watch();
  const formGeneralValues = JSON.parse(localStorage.getItem(FORM_GENERAL_NAME)) || {};

  useEffect(() => {
    const beforeValues = JSON.parse(localStorage.getItem(FORM_NAME)) || {};
    Object.keys(beforeValues).forEach((key) => {
      setTimeout(() => setValue(key, beforeValues[key]), 0);
      if (key === 'exercises' && beforeValues?.exercises?.length) update(beforeValues[key]);
    });
    setTimeout(() => setFocus('actuallySmokes'), 100);
  }, []);

  useEffect(() => {
    localStorage.setItem(FORM_NAME, JSON.stringify(formValues));
  }, [formValues]);

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === 'doesExercise' && value === 'true') {
      append({
        name: '',
        since: '',
        frequency: '',
        time: '',
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <form
        className="mb-14"
        onChange={onChange}
        onSubmit={handleSubmit(() => {})}
      >
        <Title text={t('currently')} level={3} />
        <Container>
          <Select
            label={t('actuallySmokes')}
            id="actuallySmokes"
            options={trueFalseOptions}
            required
            register={register}
            error={errors?.actuallySmokes}
          />
          {formValues.actuallySmokes === 'true' && (
          <>
            <Input
              label={t('cigarettesPerDay')}
              id="cigarettesPerDay"
              type="number"
              required
              register={register}
              error={errors?.cigarettesPerDay}
            />
            <Input
              label={t('yearsSmoking')}
              id="yearsSmoking"
              type="number"
              required
              register={register}
              error={errors?.yearsSmoking}
            />
            <Select
              label={t('triedToQuit')}
              id="triedToQuit"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.triedToQuit}
            />
            {formValues.triedToQuit === 'true' && (
            <Input
              label={t('quitTime')}
              id="quitTime"
              type="text"
              required
              register={register}
              error={errors?.quitTime}
            />
            )}
          </>
          )}
          <Select
            label={t('actuallyVapes')}
            id="actuallyVapes"
            options={trueFalseOptions}
            required
            register={register}
            error={errors?.actuallyVapes}
          />
          <Select
            label={t('actuallyDrinks')}
            id="actuallyDrinks"
            options={trueFalseOptions}
            required
            register={register}
            error={errors?.actuallyDrinks}
          />
          {formValues.actuallyDrinks === 'true' && (
          <Input
            label={t('drinksFrequency')}
            id="drinksFrequency"
            type="text"
            required
            register={register}
            error={errors?.drinksFrequency}
          />
          )}
          <Select
            label={t('actuallyDrugs')}
            id="actuallyDrugs"
            options={trueFalseOptions}
            required
            register={register}
            error={errors?.actuallyDrugs}
          />
          {formValues.actuallyDrugs === 'true' && (
          <>
            <Input
              label={t('drugsFrequency')}
              id="drugsFrequency"
              required
              register={register}
              error={errors?.drugsFrequency}
            />
            <Select
              label={t('intravenousDrugs')}
              id="intravenousDrugs"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.intravenousDrugs}
            />
          </>
          )}
          <Select
            label={t('hasAsthma')}
            id="hasAsthma"
            options={trueFalseOptions}
            required
            register={register}
            error={errors?.hasAsthma}
          />
          <Select
            label={t('usingBiPAP')}
            id="usingBiPAP"
            options={trueFalseOptions}
            required
            register={register}
            error={errors?.usingBiPAP}
          />
          <Select
            label={t('doesExercise')}
            id="doesExercise"
            options={trueFalseOptions}
            required
            register={register}
            error={errors?.doesExercise}
          />

        </Container>
        {formValues.doesExercise === 'true' && (
        <>
          <Title text={t('excerciseTitle')} level={4} />
          {fields.map((field, index) => (
            <div key={field.id} className="grid-cols-1 grid md:grid-flow-row-dense md:grid-cols-12 grid-rows-1 gap-x-4 hover:grid-flow-row border-slate-200 border-solid border-2 rounded-lg p-5 mb-3">
              {/* Mandar como exerciseType  */}
              <div className="col-span-2">
                <Input
                  label={t('exerciseName')}
                  id={`exercises.${index}.name`}
                  type="text"
                  required
                  register={register}
                  error={errors?.exercises?.[index]?.name}
                />
              </div>
              <div className="col-span-3">
                <Input
                  label={t('exerciseSince')}
                  id={`exercises.${index}.since`}
                  type="text"
                  required
                  register={register}
                  error={errors?.exercises?.[index]?.since}
                />
              </div>
              <div className="col-span-3">
                <Input
                  label={t('exerciseFrequency')}
                  id={`exercises.${index}.frequency`}
                  type="text"
                  required
                  register={register}
                  error={errors?.exercises?.[index]?.frequency}
                />
              </div>
              <div className="col-span-3">
                <Input
                  label={t('exerciseTime')}
                  id={`exercises.${index}.time`}
                  type="text"
                  required
                  register={register}
                  error={errors?.exercises?.[index]?.time}
                />
              </div>
              {index === fields.length - 1 && (
              <button
                type="button"
                onClick={() => append({})}
                className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                aria-label="Add"
              >
                <Plus />
              </button>
              )}
              {index !== fields.length - 1 && (
              <button
                type="button"
                onClick={() => remove(index)}
                className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                aria-label="Remove"
              >
                <Minus />
              </button>
              )}
            </div>
          ))}
        </>
        )}
        <Container>
          {/* Solo si es hombre y el procedimiento es para la disfuncion erectil */}
          {formGeneralValues.gender === 'M' && formGeneralValues.procedure === '14' && (
          <>
            <Input
              label={t('hoursOfSleep')}
              id="sleepHours"
              type="number"
              required
              register={register}
              error={errors?.sleepHours}
            />
            <Select
              label={t('takesSleepingPills')}
              id="takesSleepingPills"
              required
              register={register}
              error={errors?.takesSleepingPills}
              options={trueFalseOptions}
            />
          </>
          )}
          {formGeneralValues.gender === 'M' && (
          <Select
            label={t('anxietyDepression')}
            id="anxietyDepression"
            options={trueFalseOptions}
            required
            register={register}
            error={errors?.anxietyDepression}
          />
          )}
          {/* Solo si es hombre y el procedimiento es para la disfuncion erectil */}
          {formGeneralValues.gender === 'M' && formGeneralValues.procedure === '14' && (
          <>
            <Select
              label={t('takesAnxietyDepressionPills')}
              id="takesAnxietyDepressionPills"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.takesAnxietyDepressionPills}
            />
            <Select
              label={t('feelsStressed')}
              id="feelsStressed"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.feelsStressed}
            />
            <Select
              label={t('morningErections')}
              id="morningErections"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.morningErections}
            />
            {formValues.morningErections === 'true' && (
              <Input
                label={t('morningErectionsAmount')}
                id="morningErectionsAmount"
                type="number"
                required
                register={register}
                error={errors?.morningErectionsAmount}
              />
            )}
            <Select
              label={t('erectionProblems')}
              id="erectionProblems"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.erectionProblems}
            />
            {formValues.erectionProblems === 'true' && (
              <Input
                label={t('erectionProblemsSince')}
                id="erectionProblemsSince"
                required
                register={register}
                error={errors?.erectionProblemsSince}
              />
            )}
          </>
          )}
        </Container>
        {formValues.erectionProblems === 'true' && (
        <Input
          label={t('erectionProblemsDescription')}
          id="erectionProblemsDescription"
          type="text"
          required
          register={register}
          error={errors?.erectionProblemsDescription}
        />
        )}
        <Container>
          {/* Solo si es hombre y el procedimiento es para la disfuncion erectil */}
          {formGeneralValues.gender === 'M' && formGeneralValues.procedure === '14' && (
          <>
            <Select
              label={t('maintainErectionProblems')}
              id="maintainErectionProblems"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.maintainErectionProblems}
            />
            <Select
              label={t('erectionMoreThan6Hours')}
              id="erectionMoreThan6Hours"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.erectionMoreThan6Hours}
            />
            {formValues.erectionMoreThan6Hours === 'true' && (
            <>
              <Input
                label={t('erectionMoreThan6HoursSince')}
                id="erectionMoreThan6HoursSince"
                type="text"
                required
                register={register}
                error={errors?.erectionMoreThan6HoursSince}
              />
              <Input
                label={t('erectionMoreThan6HoursResolution')}
                id="erectionMoreThan6HoursResolution"
                type="text"
                required
                register={register}
                error={errors?.erectionMoreThan6HoursResolution}
              />
              <Select
                label={t('erectionMoreThan6HoursMedicalAttention')}
                id="erectionMoreThan6HoursMedicalAttention"
                options={trueFalseOptions}
                required
                register={register}
                error={errors?.erectionMoreThan6HoursMedicalAttention}
              />
            </>
            )}
            <Select
              label={t('penileCurvature')}
              id="penileCurvature"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.penileCurvature}
            />
            {formValues.penileCurvature === 'true' && (
              <>
                <Input
                  label={t('penileCurvatureIntensity')}
                  id="penileCurvatureIntensity"
                  type="text"
                  required
                  register={register}
                  error={errors?.penileCurvatureIntensity}
                />
                <Input
                  label={t('penileCurvatureDirection')}
                  id="penileCurvatureDirection"
                  type="text"
                  required
                  register={register}
                  error={errors?.penileCurvatureDirection}
                />
                <Select
                  label={t('penileCurvaturePain')}
                  id="penileCurvaturePain"
                  required
                  options={trueFalseOptions}
                  register={register}
                  error={errors?.penileCurvaturePain}
                />
                <Select
                  label={t('penileCurvatureCoitus')}
                  id="penileCurvatureCoitus"
                  options={trueFalseOptions}
                  required
                  register={register}
                  error={errors?.penileCurvatureCoitus}
                />
                <Select
                  label={t('prpInjected')}
                  id="prpInjected"
                  options={trueFalseOptions}
                  required
                  register={register}
                  error={errors?.prpInjected}
                />
                <Select
                  label={t('stemCellsTreatment')}
                  id="stemCellsTreatment"
                  options={trueFalseOptions}
                  required
                  register={register}
                  error={errors?.stemCellsTreatment}
                />
                <Select
                  label={t('vascularRegenerationTherapy')}
                  id="vascularRegenerationTherapy"
                  options={trueFalseOptions}
                  required
                  register={register}
                  error={errors?.vascularRegenerationTherapy}
                />
              </>
            )}
          </>
          )}
          {/* Solo si es hombre y el procedimiento es vaporizacion */}
          {formGeneralValues.gender === 'M' && formGeneralValues.procedure === '15' && (
          <>
            <Select
              label={t('prostateCancerFamily')}
              id="prostateCancerFamily"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.prostateCancerFamily}
            />
            <Select
              label={t('psaTest')}
              id="psaTest"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.psaTest}
            />
            {formValues.psaTest === 'true' && (
              <>
                <Input
                  label={t('psaTestDate')}
                  id="psaTestDate"
                  type="date"
                  required
                  register={register}
                  error={errors?.psaTestDate}
                />
                <Input
                  label={t('psaTestResult')}
                  id="psaTestResult"
                  type="text"
                  required
                  register={register}
                  error={errors?.psaTestResult}
                />
              </>
            )}
            <Select
              label={t('prostateBiopsy')}
              id="prostateBiopsy"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.prostateBiopsy}
            />
            {formValues.prostateBiopsy === 'true' && (
              <>
                <Input
                  label={t('prostateBiopsyDate')}
                  id="prostateBiopsyDate"
                  type="date"
                  required
                  register={register}
                  error={errors?.prostateBiopsyDate}
                />
                <Input
                  label={t('prostateBiopsyResult')}
                  id="prostateBiopsyResult"
                  type="text"
                  required
                  register={register}
                  error={errors?.prostateBiopsyResult}
                />
              </>
            )}
            <Select
              label={t('nightlyUrination')}
              id="nightlyUrination"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.nightlyUrination}
            />
            {formValues.nightlyUrination === 'true' && (
              <Input
                label={t('nightlyUrinationTimes')}
                id="nightlyUrinationTimes"
                type="number"
                required
                register={register}
                error={errors?.nightlyUrinationTimes}
              />
            )}
            <Select
              label={t('dripsAfterUrination')}
              id="dripsAfterUrination"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.dripsAfterUrination}
            />
            <Select
              label={t('urinaryInfection')}
              id="frequentUrination"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.frequentUrination}
            />
            <Select
              label={t('slowUrination')}
              id="slowUrination"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.slowUrination}
            />
          </>
          )}
        </Container>
        {formGeneralValues.gender === 'M' && formGeneralValues.procedure === '15' && (
        <>
          <Title text={t('miando')} level={3} />
          <Container>
            <Select
              label={t('frequentPause')}
              id="frequentPause"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.frequentPause}
            />
            <Select
              label={t('weakStream')}
              id="weakStream"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.weakStream}
            />
            <Select
              label={t('strains')}
              id="strains"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.strains}
            />
            <Select
              label={t('bled')}
              id="bled"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.bled}
            />
            <Select
              label={t('urinaryCatheter')}
              id="urinaryCatheter"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.urinaryCatheter}
            />
            {formValues.urinaryCatheter === 'true' && (
            <Input
              label={t('urinaryCatheterTime')}
              id="urinaryCatheterTime"
              type="text"
              required
              register={register}
              error={errors?.urinaryCatheterTime}
            />
            )}
            <Select
              label={t('urinaryInfections')}
              id="urinaryInfections"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.urinaryInfections}
            />
            {formValues.urinaryInfections === 'true' && (
            <Input
              label={t('urinaryInfectionsAntibiotics')}
              id="urinaryInfectionsAntibiotics"
              type="text"
              required
              register={register}
              error={errors?.urinaryInfectionsAntibiotics}
            />
            )}
            <Select
              label={t('urinaryIncontinence')}
              id="urinaryIncontinence"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.urinaryIncontinence}
            />
            {formValues.urinaryIncontinence === 'true' && (
            <Input
              label={t('urinaryIncontinenceSince')}
              id="urinaryIncontinenceSince"
              type="text"
              required
              register={register}
              error={errors?.urinaryIncontinenceSince}
            />
            )}
            <Select
              label={t('takesBPHMedications')}
              id="takesBPHMedications"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.takesBPHMedications}
            />
            {formValues.takesBPHMedications === 'true' && (
            <>
              <Input
                label={t('type')}
                id="bphMedications"
                type="text"
                required
                register={register}
                error={errors?.bphMedications}
              />
              <Input
                label={t('bphMedicationsSince')}
                id="bphMedicationsSince"
                type="text"
                required
                register={register}
                error={errors?.bphMedicationsSince}
              />
            </>
            )}
          </Container>
        </>
        )}
        {[14, 15].includes(Number(formGeneralValues.procedure)) && (
        <Container>
          <Select
            label={t('takesViagra')}
            id="takesViagra"
            options={trueFalseOptions}
            required
            register={register}
            error={errors?.takesViagra}
          />
          {formValues.takesViagra === 'true' && (
          <>
            <Input
              label={t('takesViagraSince')}
              id="takesViagraSince"
              type="date"
              required
              register={register}
              error={errors?.takesViagraSince}
            />
            <Input
              label={t('viagraEffectiveness')}
              id="viagraEffectiveness"
              type="text"
              required
              register={register}
              error={errors?.viagraEffectiveness}
            />
            <Input
              label={t('viagraFrequency')}
              id="viagraFrequency"
              type="text"
              required
              register={register}
              error={errors?.viagraFrequency}
            />
            <Input
              label={t('viagraHowToTake')}
              id="viagraHowToTake"
              required
              register={register}
              error={errors?.viagraHowToTake}
            />
            <Select
              label={t('takesNaturalRemedies')}
              id="takesNaturalRemedies"
              options={trueFalseOptions}
              required
              register={register}
              error={errors?.takesNaturalRemedies}
            />
            {formValues.takesNaturalRemedies === 'true' && (
            <>
              <Input
                label={t('type')}
                id="naturalRemediesType"
                type="text"
                required
                register={register}
                error={errors?.naturalRemediesType}
              />
              <Input
                label={t('naturalRemediesEffectiveness')}
                id="naturalRemediesEffectiveness"
                type="text"
                required
                register={register}
                error={errors?.naturalRemediesEffectiveness}
              />
              <Input
                label={t('naturalRemediesWhereToGet')}
                id="naturalRemediesWhereToGet"
                type="text"
                required
                register={register}
                error={errors?.naturalRemediesWhereToGet}
              />
              <Select
                label={t('injectedMedication')}
                id="injectedMedication"
                options={trueFalseOptions}
                required
                register={register}
                error={errors?.injectedMedication}
              />
              {formValues.injectedMedication === 'true' && (
              <>
                <Input
                  label={t('injectedMedicationTimes')}
                  id="injectedMedicationTimes"
                  type="text"
                  required
                  register={register}
                  error={errors?.injectedMedicationTimes}
                />
                <Input
                  label={t('injectedMedicationEffectiveness')}
                  id="injectedMedicationEffectiveness"
                  type="text"
                  required
                  register={register}
                  error={errors?.injectedMedicationEffectiveness}
                />
              </>
              )}
            </>
            )}
          </>
          )}
        </Container>
        )}
        {children}
      </form>
    </FormProvider>
  );
};

export default SaludGeneral;
