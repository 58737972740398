import { zodResolver } from '@hookform/resolvers/zod';
import Container from 'components/Container';
import Input from 'components/Input';
import Select from 'components/Select';
import Title from 'components/Title';
import Minus from 'components/icons/Minus';
import Plus from 'components/icons/Plus';
import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { proceduesEnum, servicesEnum, trueFalseOptions } from 'utils/enums';
import { surgeryHistorySchema } from 'utils/validations/surgeryHistorySchema';
import { useTranslation } from 'react-i18next';

const FORM_NAME = 'FORM_VALUES_HISTORIAL_QUIRURGICO';

export const HistorialQururgico = ({ children }) => {
  const { t } = useTranslation('global');
  const {
    register,
    watch,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
    setFocus,
  } = useForm({
    resolver: zodResolver(surgeryHistorySchema()),
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldUnregister: true,
  });

  const {
    fields: previousSurgeriesFields,
    append: appendPreviousSurgery,
    remove: removePreviousSurgery,
    update: updatePreviousSurgery,
  } = useFieldArray({
    control,
    name: 'previousSurgeries',
  });

  const {
    fields: previousBariatricSurgeriesFields,
    append: appendPreviousBariatricSurgery,
    remove: removePreviousBariatricSurgery,
    update: updatePreviousBariatricSurgery,
  } = useFieldArray({
    control,
    name: 'previousBariatricSurgeries',
  });

  const {
    fields: previousPlasticSurgeriesFields,
    append: appendPreviousPlasticSurgery,
    remove: removePreviousPlasticSurgery,
    update: updatePreviousPlasticSurgery,
  } = useFieldArray({
    control,
    name: 'previousPlasticSurgeries',
  });

  const {
    fields: previousProstateSurgeriesFields,
    append: appendProstateSurgery,
    remove: removeProstateSurgery,
    update: updateProstateSurgery,
  } = useFieldArray({
    control,
    name: 'previousProstateSurgeries',
  });

  const {
    fields: previousPelvicSurgeriesFields,
    append: appendPelvicSurgery,
    remove: removePelvicSurgery,
    update: updatePelvicSurgery,
  } = useFieldArray({
    control,
    name: 'previousPelvicSurgeries',
  });

  const {
    fields: testicleSurgeriesFields,
    append: appendTesticleSurgeries,
    remove: removeTesticleSurgeries,
    update: updateTesticleSurgeries,
  } = useFieldArray({
    control,
    name: 'testicleSurgeriesList',
  });

  const formValues = watch();
  const formGeneralValues = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES'));

  useEffect(() => {
    const beforeValues = JSON.parse(localStorage.getItem(FORM_NAME)) || {};
    const keys = Object.keys(beforeValues);
    keys.forEach((key) => {
      if (key === 'testicleSurgeriesList' && beforeValues?.testicleSurgeriesList?.length) updateTesticleSurgeries(beforeValues[key]);
      if (key === 'previousSurgeries' && beforeValues?.previousSurgeries?.length) updatePreviousSurgery(beforeValues[key]);
      if (key === 'previousBariatricSurgeries' && beforeValues?.previousBariatricSurgeries?.length) updatePreviousBariatricSurgery(beforeValues[key]);
      if (key === 'previousPlasticSurgeries' && beforeValues?.previousPlasticSurgeries?.length) updatePreviousPlasticSurgery(beforeValues[key]);
      if (key === 'previousProstateSurgeries' && beforeValues?.previousProstateSurgeries?.length) updateProstateSurgery(beforeValues[key]);
      if (key === 'previousPelvicSurgeries' && beforeValues?.previousPelvicSurgeries?.length) updatePelvicSurgery(beforeValues[key]);
      setTimeout(() => setValue(key, beforeValues[key]), 0);
    });
    setTimeout(() => setFocus('hasBeenSurgery'), 100);
  }, []);

  useEffect(() => {
    localStorage.setItem(FORM_NAME, JSON.stringify(formValues));
  }, [formValues]);

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === 'hasBeenSurgery' && value === 'true') {
      appendPreviousSurgery({
        wich: '',
        age: '',
        date: '',
        complications: '',
      });
    }
    if (name === 'bariatricSurgeryBefore' && value === 'true') {
      appendPreviousBariatricSurgery({
        wich: '',
        age: '',
        date: '',
        complications: '',
      });
    }
    if (name === 'plasticSurgeryBefore' && value === 'true') {
      appendPreviousPlasticSurgery({
        type: '',
        date: '',
      });
    }
    if (name === 'prostateSurgeryBefore' && value === 'true') {
      appendProstateSurgery({
        type: '',
        date: '',
      });
    }
    if (name === 'pelvicSurgeryBefore' && value === 'true') {
      appendPelvicSurgery({
        type: '',
        date: '',
      });
    }
    if (name === 'testicleSurgery' && value === 'true') {
      appendTesticleSurgeries({
        date: '',
        complications: '',
      });
    }
  };

  return (
    <form
      className="mb-14"
      onChange={onChange}
      onSubmit={handleSubmit(() => {})}
    >
      <Container>
        <Select
          label={t('hasBeenSurgery')}
          id="hasBeenSurgery"
          options={trueFalseOptions}
          error={errors?.hasBeenSurgery}
          register={register}
          required
        />
      </Container>
      {formValues.hasBeenSurgery === 'true' && (
        <>
          {/* Mandar como surgery */}
          {/* TODO */}
          <Title text={t('surgeriesTitle')} level={3} />
          {previousSurgeriesFields.map((fields, index) => (
            <div key={fields.id} className="grid-cols-1 grid md:grid-flow-row-dense md:grid-cols-12 grid-rows-1 gap-x-4 hover:grid-flow-row border-slate-200 border-solid border-2 rounded-lg p-5 mb-3">
              <div className="col-span-3">
                <Input
                  label={t('wich')}
                  id={`previousSurgeries[${index}].wich`}
                  type="text"
                  register={register}
                  error={errors?.previousSurgeries?.[index]?.wich}
                />
              </div>
              <div className="col-span-3">
                <Input
                  label={t('age')}
                  id={`previousSurgeries[${index}].age`}
                  type="text"
                  register={register}
                  error={errors?.previousSurgeries?.[index]?.age}
                />
              </div>
              <div className="col-span-3 md:col-span-2">
                <Input
                  label={t('surgeryDate')}
                  id={`previousSurgeries[${index}].date`}
                  type="date"
                  register={register}
                  error={errors?.previousSurgeries?.[index]?.date}
                />
              </div>
              <div className="col-span-3">
                <Input
                  label={t('complications')}
                  id={`previousSurgeries[${index}].complications`}
                  type="text"
                  register={register}
                  error={errors?.previousSurgeries?.[index]?.complications}
                />
              </div>
              <div className="flex col-span-1 md:justify-end justify-center">
                {index === previousSurgeriesFields.length - 1 && (
                <button
                  type="button"
                  className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                  onClick={() => appendPreviousSurgery({})}
                  aria-label="Add medication"
                >
                  <Plus />
                </button>
                )}
                {index !== previousSurgeriesFields.length - 1 && (
                <button
                  type="button"
                  className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                  onClick={() => removePreviousSurgery(index)}
                  aria-label="Remove medication"
                >
                  <Minus />
                </button>
                )}
              </div>
            </div>
          ))}
          <Container>
            <Select
              label={t('anestheticProblems')}
              id="anestheticProblems"
              options={trueFalseOptions}
              error={errors?.anestheticProblems}
              register={register}
              required
            />
            {formValues.anestheticProblems === 'true' && (
              <Input
                label={t('anestheticProblemsDescription')}
                id="anestheticProblemsDescription"
                type="text"
                register={register}
                error={errors?.anestheticProblemsDescription}
                required
                col={2}
              />
            )}
          </Container>
        </>
      )}
      {(formGeneralValues.service === servicesEnum.BARIATRIC_SURGERY
        || formGeneralValues.service === servicesEnum.PLASTIC_SURGERY) && (
        <>
          <Container>
            <Select
              label={t('bariatricSurgeryBefore')}
              id="bariatricSurgeryBefore"
              options={trueFalseOptions}
              error={errors?.bariatricSurgeryBefore}
              register={register}
              required
            />
          </Container>
          {formValues.bariatricSurgeryBefore === 'true' && (
          <>
            {/* MANDAR COMO bariatrics */}
            <Title text={t('bariatricSurgeryTitle')} level={3} />
            {previousBariatricSurgeriesFields.map((field, index) => (
              <div key={field.id} id={`previousBariatricSurgeriesFields${index}`} className="grid-cols-1 grid md:grid-flow-row-dense md:grid-cols-12 grid-rows-1 gap-x-4 hover:grid-flow-row border-slate-200 border-solid border-2 rounded-lg p-5 mb-3">
                <div className="col-span-3">
                  <Input
                    label={t('wich')}
                    id={`previousBariatricSurgeries[${index}].wich`}
                    type="text"
                    register={register}
                    error={errors?.previousBariatricSurgeries?.[index]?.wich}
                  />
                </div>
                <div className="col-span-3">
                  <Input
                    label={t('age')}
                    id={`previousBariatricSurgeries[${index}].age`}
                    type="text"
                    register={register}
                    error={errors?.previousBariatricSurgeries?.[index]?.age}
                  />
                </div>
                <div className="col-span-3 md:col-span-2">
                  <Input
                    label={t('surgeryDate')}
                    id={`previousBariatricSurgeries[${index}].date`}
                    type="date"
                    register={register}
                    error={errors?.previousBariatricSurgeries?.[index]?.date}
                  />
                </div>
                <div className="col-span-3">
                  <Input
                    label={t('complications')}
                    id={`previousBariatricSurgeries[${index}].complications`}
                    type="text"
                    register={register}
                    error={errors?.previousBariatricSurgeries?.[index]?.complications}
                  />
                </div>
                <div className="flex col-span-1 md:justify-end justify-center">
                  {index === previousBariatricSurgeriesFields.length - 1 && (
                  <button
                    type="button"
                    className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                    onClick={() => appendPreviousBariatricSurgery({})}
                    aria-label="Add medication"
                  >
                    <Plus />
                  </button>
                  )}
                  {index !== previousBariatricSurgeriesFields.length - 1 && (
                  <button
                    type="button"
                    className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                    onClick={() => {
                      removePreviousBariatricSurgery(index);
                    }}
                    aria-label="Remove medication"
                  >
                    <Minus />
                  </button>
                  )}
                </div>
              </div>
            ))}
          </>
          )}
        </>
      )}
      {formGeneralValues.service === servicesEnum.BARIATRIC_SURGERY && (
        <>
          <Container>
            <Select
              label={t('plasticSurgeryBefore')}
              id="plasticSurgeryBefore"
              register={register}
              error={errors?.plasticSurgeryBefore}
              options={trueFalseOptions}
            />
          </Container>
          {formValues.plasticSurgeryBefore === 'true' && (
            <>
              {/* Mandar como plasticSurgery */}
              <Title text={t('plasticSurgeryTitle')} level={3} />
              {previousPlasticSurgeriesFields.map((field, index) => (
                <div key={field.id} className="grid-cols-1 grid md:grid-flow-row-dense md:grid-cols-11 grid-rows-1 gap-x-4 hover:grid-flow-row border-slate-200 border-solid border-2 rounded-lg p-5 mb-3">
                  <div className="col-span-5">
                    <Input
                      label={t('type')}
                      id={`previousPlasticSurgeries[${index}].type`}
                      type="text"
                      register={register}
                      error={errors?.previousPlasticSurgeries?.[index]?.type}
                    />
                  </div>
                  <div className="col-span-5">
                    <Input
                      id={`previousPlasticSurgeries[${index}].date`}
                      label={t('yearOfSurgery')}
                      type="date"
                      register={register}
                      error={errors?.previousPlasticSurgeries?.[index]?.date}
                    />
                  </div>
                  <div className="flex col-span-1 md:justify-end justify-center">
                    {index === previousPlasticSurgeriesFields.length - 1 && (
                    <button
                      type="button"
                      className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                      onClick={() => appendPreviousPlasticSurgery({})}
                      aria-label="Add"
                    >
                      <Plus />
                    </button>
                    )}
                    {index !== previousPlasticSurgeriesFields.length - 1 && (
                    <button
                      type="button"
                      className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                      onClick={() => removePreviousPlasticSurgery(index)}
                      aria-label="Remove"
                    >
                      <Minus />
                    </button>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}
      {formGeneralValues.service === servicesEnum.UROLOGY && formGeneralValues.gender === 'M' && (
        <>
          <Container>
            <Select
              label={t('prostateSurgeryBefore')}
              id="prostateSurgeryBefore"
              options={trueFalseOptions}
              error={errors?.prostateSurgeryBefore}
              register={register}
              required
            />
          </Container>
          {formValues.prostateSurgeryBefore === 'true' && (
          <>
            <Title text={t('prostateSurgeryTitle')} level={3} />
            {previousProstateSurgeriesFields.map((field, index) => (
              <div key={field.id} className="grid-cols-1 grid md:grid-flow-row-dense md:grid-cols-11 grid-rows-1 gap-x-4 hover:grid-flow-row border-slate-200 border-solid border-2 rounded-lg p-5 mb-3">
                <div className="col-span-5">
                  <Input
                    label={t('type')}
                    id={`previousProstateSurgeries[${index}].type`}
                    type="text"
                    register={register}
                    error={errors?.previousProstateSurgeries?.[index]?.type}
                  />
                </div>
                <div className="col-span-5">
                  <Input
                    id={`previousProstateSurgeries[${index}].date`}
                    label={t('yearOfSurgery')}
                    type="date"
                    register={register}
                    error={errors?.previousProstateSurgeries?.[index]?.date}
                  />
                </div>
                <div className="flex col-span-1 md:justify-end justify-center">
                  {index === previousProstateSurgeriesFields.length - 1 && (
                  <button
                    type="button"
                    className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                    onClick={() => appendProstateSurgery({})}
                    aria-label="Add"
                  >
                    <Plus />
                  </button>
                  )}
                  {index !== previousProstateSurgeriesFields.length - 1 && (
                  <button
                    type="button"
                    className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                    onClick={() => removeProstateSurgery(index)}
                    aria-label="Remove"
                  >
                    <Minus />
                  </button>
                  )}
                </div>
              </div>
            ))}
          </>
          )}
          <Container>
            <Select
              label={t('pelvicSurgeryBefore')}
              id="pelvicSurgeryBefore"
              options={trueFalseOptions}
              error={errors?.pelvicSurgeryBefore}
              register={register}
              required
            />
          </Container>
          {formValues.pelvicSurgeryBefore === 'true' && (
          <>
            <Title text={t('pelvicSurgeryTitle')} level={3} />
            {previousPelvicSurgeriesFields.map((field, index) => (
              <div key={field.id} className="grid-cols-1 grid md:grid-flow-row-dense md:grid-cols-11 grid-rows-1 gap-x-4 hover:grid-flow-row border-slate-200 border-solid border-2 rounded-lg p-5 mb-3">
                <div className="col-span-5">
                  <Input
                    label={t('type')}
                    id={`previousPelvicSurgeries[${index}].type`}
                    type="text"
                    register={register}
                    error={errors?.previousPelvicSurgeries?.[index]?.type}
                  />
                </div>
                <div className="col-span-5">
                  <Input
                    id={`previousPelvicSurgeries[${index}].date`}
                    label={t('yearOfSurgery')}
                    type="date"
                    register={register}
                    error={errors?.previousPelvicSurgeries?.[index]?.date}
                  />
                </div>
                <div className="flex col-span-1 md:justify-end justify-center">
                  {index === previousPelvicSurgeriesFields.length - 1 && (
                  <button
                    type="button"
                    className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                    onClick={() => appendPelvicSurgery({})}
                    aria-label="Add"
                  >
                    <Plus />
                  </button>
                  )}
                  {index !== previousPelvicSurgeriesFields.length - 1 && (
                  <button
                    type="button"
                    className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                    onClick={() => removePelvicSurgery(index)}
                    aria-label="Remove"
                  >
                    <Minus />
                  </button>
                  )}
                </div>
              </div>
            ))}
          </>
          )}
        </>
      )}
      {formGeneralValues.procedure === proceduesEnum.VASECTOMY_REVERSAL && (
        <>
          <Title text={t('vasectomyTitle')} level={3} />
          <Container>
            <Input
              label={t('vasectomyDate')}
              id="vasectomyDate"
              type="date"
              register={register}
              error={errors?.vasectomyDate}
              required
            />
            <Input
              label={t('vasectomyPlace')}
              id="vasectomyPlace"
              type="text"
              register={register}
              error={errors?.vasectomyPlace}
              required
            />
            <Select
              label={t('vasectomyComplications')}
              id="vasectomyComplications"
              options={trueFalseOptions}
              error={errors?.vasectomyComplications}
              register={register}
              required
            />
          </Container>
          {formValues.vasectomyComplications === 'true' && (
            <Input
              label={t('vasectomyComplicationsDescription')}
              id="vasectomyComplicationsDescription"
              type="text"
              register={register}
              error={errors?.vasectomyComplicationsDescription}
              required
            />
          )}
          <Container>
            <Select
              label={t('testiclePain')}
              id="testiclePain"
              options={trueFalseOptions}
              error={errors?.testiclePain}
              register={register}
              required
            />
          </Container>
          {formValues.testiclePain === 'true' && (
            <Input
              label={t('testiclePainDescription')}
              id="testiclePainDescription"
              type="text"
              register={register}
              error={errors?.testiclePainDescription}
              required
            />
          )}
          <Container>
            <Select
              label={t('testicleSurgery')}
              id="testicleSurgery"
              options={trueFalseOptions}
              error={errors?.testicleSurgery}
              register={register}
              required
            />
          </Container>
          {formValues.testicleSurgery === 'true' && (
            <>
              {/* Mandar como testicleSurgeryList */}
              <Title text={t('testicleSurgeryTitle')} level={3} />
              {testicleSurgeriesFields.map((field, index) => (
                <div key={field.id} className="grid-cols-1 grid md:grid-flow-row-dense md:grid-cols-11 grid-rows-1 gap-x-4 hover:grid-flow-row border-slate-200 border-solid border-2 rounded-lg p-5 mb-3">
                  <div className="col-span-5">
                    <Input
                      id={`testicleSurgeriesList[${index}].date`}
                      label={t('yearOfSurgery')}
                      type="date"
                      register={register}
                      error={errors?.testicleSurgeriesList?.[index]?.date}
                    />
                  </div>
                  <div className="col-span-5">
                    <Input
                      id={`testicleSurgeriesList[${index}].complications`}
                      label={t('complications')}
                      type="text"
                      register={register}
                      error={errors?.testicleSurgeriesList?.[index]?.complications}
                    />
                  </div>
                  <div className="flex col-span-1 md:justify-end justify-center">
                    {index === testicleSurgeriesFields.length - 1 && (
                    <button
                      type="button"
                      className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                      onClick={() => appendTesticleSurgeries({})}
                      aria-label="Add"
                    >
                      <Plus />
                    </button>
                    )}
                    {index !== testicleSurgeriesFields.length - 1 && (
                    <button
                      type="button"
                      className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                      onClick={() => removeTesticleSurgeries(index)}
                      aria-label="Remove"
                    >
                      <Minus />
                    </button>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}
      {children}
    </form>
  );
};
