/* eslint-disable no-console */
import API from 'utils/api';
import { goodRegularBadOptions, sesibilityOptions } from 'utils/enums';
import { formatValues } from 'utils/formatValues';
import { toast } from 'react-toastify';
import reset from 'utils/reset';
import { getCurrentLanguage } from 'components/ToggleLanguege';

const defaulOption = {
  id: null,
  value: null,
  label: 'Seleccione una opción',
  label_es: 'Seleccione una opción',
  label_en: 'Select an option',
};

export const getServices = async () => {
  const lang = getCurrentLanguage();
  const toastMessage = lang === 'es' ? 'Cargando servicios...' : 'Loading services...';
  const _servicesToast = toast.info(toastMessage);
  try {
    const response = await API.get('/services');
    if (!response.data) {
      setTimeout(() => {
        toast.dismiss(_servicesToast);
      }, 2000);
      return [];
    }
    const mappedServices = response.data.map((service) => ({
      ...service,
      value: service.id,
      label_es: service.name_es || service.name,
      label_en: service.name_en,
      label: service.name,
    }));
    localStorage.setItem('SERVICES', JSON.stringify(mappedServices ?? []));
    setTimeout(() => {
      toast.dismiss(_servicesToast);
    }, 2000);
    return [defaulOption, ...mappedServices];
  } catch (error) {
    setTimeout(() => {
      toast.dismiss(_servicesToast);
    }, 2000);
    console.error(error);
  }
  setTimeout(() => {
    toast.dismiss(_servicesToast);
  }, 2000);
  return [];
};

export const getProcedures = async (params = null) => {
  const lang = getCurrentLanguage();
  const toastMessage = lang === 'es' ? 'Cargando procedimientos...' : 'Loading procedures...';
  if (Number.isNaN(Number(params.serviceId))) return [];
  const _proceduresToast = toast.info(toastMessage);
  try {
    const response = await API.get('/procedures', { params });
    if (!response.data) {
      setTimeout(() => {
        toast.dismiss(_proceduresToast);
      }, 2000);
      return [];
    }
    const mappedProcedures = response.data.map((procedure) => ({
      ...procedure,
      value: procedure.id,
      label_es: procedure.name_es || procedure.name,
      label_en: procedure.name_en,
      label: procedure.name,
    }));
    localStorage.setItem('PROCEDURES', JSON.stringify(mappedProcedures ?? []));
    setTimeout(() => {
      toast.dismiss(_proceduresToast);
    }, 2000);
    return mappedProcedures;
  } catch (error) {
    console.error(error);
  }
  setTimeout(() => {
    toast.dismiss(_proceduresToast);
  }, 2000);
  return [];
};

export const postClient = async (data) => {
  try {
    const cleanValues = {};
    cleanValues.name = data.name;
    cleanValues.last_name = data.lastName;
    cleanValues.gender = data.gender === 'F' ? 'Mujer' : 'Hombre';
    // eslint-disable-next-line prefer-destructuring
    cleanValues.birth_date = new Date(data.birthdate).toISOString().split('T')[0];
    cleanValues.email = data.email;
    cleanValues.phone = data.phone;
    cleanValues.phone2 = data.phone2;
    cleanValues.address = data.address;
    cleanValues.city = data.city;
    cleanValues.state = data.state;
    cleanValues.country = data.country;
    cleanValues.zip_code = data.zipCode;
    cleanValues.emergency_name = data.contactName;
    cleanValues.emergency_phone = data.contactPhone;
    cleanValues.emergency_relationship = data.contactRelation;
    const response = await API.post('/clients', cleanValues);
    localStorage.setItem('CLIENT_ID', response.data);
    return true;
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
  }
  return false;
};

const uploadFiles = async (files, applicationId) => {
  try {
    const keys = Object.keys(files);
    const uploadPromises = keys.map((key) => {
      const formData = new FormData();
      formData.append('file', files[key]);
      formData.append('originalname', files[key].name);
      formData.append('applicationId', applicationId);
      return API.post('/files', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    });
    await Promise.all(uploadPromises);
    setTimeout(() => {
      reset();
    }, 800);
  } catch (error) {
    console.error(error);
  }
};

// Fields
// name
// time
// since
// frequency
const formatExercises = (exercises) => {
  const mapped = exercises.map((exercise) => `Nombre: ${exercise.name}, Tiempo: ${exercise.time}, Desde: ${exercise.since}, Frecuencia: ${exercise.frequency}`);
  return new Intl.ListFormat('es').format(mapped);
};

// fields
// {
//   wich: '',
//   date: '',
//   treatment: '',
// }
const formatOtherDiseases = (diseases) => {
  const mapped = diseases.map((disease) => `Enfermedad: ${disease.wich}, Fecha: ${disease.date}, Tratamiento: ${disease.treatment}`);
  return new Intl.ListFormat('es').format(mapped);
};

// Fields
// method: '',
// timeOfUse: '',
const formatContraceptivesMethods = (methods) => {
  const mapped = methods.map((method) => `Método: ${method.method}, Tiempo de uso: ${method.timeOfUse}`);
  return new Intl.ListFormat('es').format(mapped);
};

// Fields
// method: '',
// timeOfUse: '',
const formatHormones = (hormones) => {
  const mapped = hormones.map((hormone) => `Hormona: ${hormone.type}, Tiempo de uso: ${hormone.timeOfUse}`);
  return new Intl.ListFormat('es').format(mapped);
};

// Fields
// wich: '',
// age: '',
// date: '',
// complications: '',
const formatPreviousSurgeries = (surgeries) => {
  const mapped = surgeries.map((surgery) => `Cirugía: ${surgery.wich}, Edad: ${surgery.age}, Fecha: ${surgery.date}, Complicaciones: ${surgery.complications}`);
  return new Intl.ListFormat('es').format(mapped);
};

// Fields
// wich: '',
// age: '',
// date: '',
// complications: '',
const formatPreviousBariatricSurgeries = (surgeries) => {
  const mapped = surgeries.map((surgery) => `Cirugía: ${surgery.wich}, Edad: ${surgery.age}, Fecha: ${surgery.date}, Complicaciones: ${surgery.complications}`);
  return new Intl.ListFormat('es').format(mapped);
};

// Fields
// type: '',
// date: '',
const formatPreviousPlasticSurgeries = (surgeries) => {
  const mapped = surgeries.map((surgery) => `Cirugía: ${surgery.type}, Fecha: ${surgery.date}`);
  return new Intl.ListFormat('es').format(mapped);
};

// Fields
// type: '',
// date: '',
const formatPreviousProstateSurgeries = (surgeries) => {
  const mapped = surgeries.map((surgery) => `Cirugía: ${surgery.type}, Fecha: ${surgery.date}`);
  return new Intl.ListFormat('es').format(mapped);
};

// Fields
// type: '',
// date: '',
const formatPervicSurgeries = (surgeries) => {
  const mapped = surgeries.map((surgery) => `Cirugía: ${surgery.type}, Fecha: ${surgery.date}`);
  return new Intl.ListFormat('es').format(mapped);
};

// Fields
// date: '',
// complications: ''
const formatTesticularSurgeries = (surgeries) => {
  const mapped = surgeries.map((surgery) => `Fecha: ${surgery.date}, Complicaciones: ${surgery.complications}`);
  return new Intl.ListFormat('es').format(mapped);
};

export const sendAppointment = async (data) => {
  const valuesFormGeneral = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES')) || {};
  const valuesFormDetallesDeSalud = JSON.parse(localStorage.getItem('DETALLES_DE_SALUD_FORM_VALUES')) || {};
  const valuesFormHistorialMedico = JSON.parse(localStorage.getItem('FORM_VALUES_HISTORIAL_MEDICO')) || {};
  const valuesFormHistorialQuirurgico = JSON.parse(localStorage.getItem('FORM_VALUES_HISTORIAL_QUIRURGICO')) || {};
  const valuesFormSaludGeneral = JSON.parse(localStorage.getItem('FORM_VALUES_SALUD_GENERAL')) || {};
  const valuesReferencias = JSON.parse(localStorage.getItem('REFERENCIAS')) || {};
  const clientId = localStorage.getItem('CLIENT_ID');
  const appointment = {
    ...valuesFormGeneral,
    ...valuesFormDetallesDeSalud,
    ...valuesFormHistorialMedico,
    ...valuesFormHistorialQuirurgico,
    ...valuesFormSaludGeneral,
    ...valuesReferencias,
    clientId,
    procedureId: Number(valuesFormGeneral.procedure),
    serviceId: Number(valuesFormGeneral.service),
    language: getCurrentLanguage(),
    ...data,
  };

  if (appointment.patientDentalHealthDescription) {
    appointment.patientDentalHealthDescription = goodRegularBadOptions
      .find((option) => option.value === appointment.patientDentalHealthDescription).label_es;
  }
  if (appointment.dentalSensitivity) {
    appointment.dentalSensitivityTo = sesibilityOptions
      .find((option) => option.value === appointment.dentalSensitivityTo).label_es;
  }
  if (appointment?.exercises?.length) {
    appointment.exerciseType = formatExercises(appointment.exercises);
  }
  if (appointment?.otherDiseases?.length) {
    appointment.otherDiseaseList = formatOtherDiseases(appointment.otherDiseases);
  }
  if (appointment?.contraceptivesMethods?.length) {
    appointment.contraceptivesMethods = formatContraceptivesMethods(appointment.contraceptivesMethods);
  }
  if (appointment?.hormones?.length) {
    appointment.hormonesType = formatHormones(appointment.wichHormones);
  }
  if (appointment?.previousSurgeries?.length) {
    appointment.surgery = formatPreviousSurgeries(appointment.previousSurgeries);
  }
  if (appointment?.previousBariatricSurgeries?.length) {
    appointment.bariatrics = formatPreviousBariatricSurgeries(appointment.previousBariatricSurgeries);
  }
  if (appointment?.previousPlasticSurgeries?.length) {
    appointment.plasticSurgery = formatPreviousPlasticSurgeries(appointment.previousPlasticSurgeries);
  }
  if (appointment?.previousProstateSurgeries?.length) {
    appointment.prostateSurgery = formatPreviousProstateSurgeries(appointment.previousProstateSurgeries);
  }
  if (appointment?.pervicSurgeries?.length) {
    appointment.pelvicSurgery = formatPervicSurgeries(appointment.pervicSurgeries);
  }
  if (appointment?.testicularSurgeries?.length) {
    appointment.testicularSurgery = formatTesticularSurgeries(appointment.testicularSurgeries);
  }
  if (appointment?.pregnantTimes) {
    appointment.pregnantTimes = parseInt(appointment.pregnantTimes, 10);
  }
  if (appointment?.package) {
    appointment.packageId = parseInt(appointment.package, 10);
  }

  try {
    const response = await API.post('/applications', formatValues(appointment));
    localStorage.setItem('APPLICATION_ID', response.data.data);
    localStorage.setItem('EMAIL_SENT_TO', appointment.email);
    if (appointment.files && response.data.data) {
      await uploadFiles(appointment.files, response.data.data);
    }
    setTimeout(() => {
      reset();
    }, 500);
  } catch (error) {
    console.error(error);
    toast.error(error.response.data.message);
  }
};
