import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ArrowRight from './icons/ArrowRight';
import Facebook from './icons/Facebook';
import Instagram from './icons/Instagram';
import Mail from './icons/Mail';
import Phone from './icons/Phone';
import Whatsapp from './icons/Whatsapp';

const Footer = () => {
  const { t } = useTranslation('footer');

  return (
    <div className="bg-[#061939] text-white">
      <div
        className="text-center flex px-4 md:px-16 justify-between items-center border-b-[1px] border-[#FFFFFF26]"
      >
        <div className="p-4">
          <img src="assets/Logo.png" alt="Logo" className="h-24 filter grayscale" />
        </div>
        <div className="p-4 font-bold tracking-wider hidden md:block">
          {t('priority')}
        </div>
        <div>
          <button
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
              toast.info(t('toastAlready'), {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                draggable: true,
              });
            }}
            type="button"
            className="px-4 h-14 md:h-10 bg-white text-black font-bold rounded-md"
          >
            {t('button')}
          </button>
        </div>
      </div>
      <div
        className="bg-[#061939] text-white flex flex-col md:flex-row justify-between md:px-32"
      >
        <div className="p-8 py-9 w-full md:w-1/2  border-r-[1px] md:border-[#FFFFFF26]">
          <h4 className="font-bold mb-2 tracking-wider">{t('About')}</h4>
          <p>
            {t('aboutUsP')}
          </p>
        </div>
        <div className="p-7 md:p-8 w-full md:w-1/2 flex flex-col sm:flex-row gap-4">
          <div className="w-full md:w-1/2">
            <h4 className="font-bold mb-2 tracking-wider">{t('Services')}</h4>
            <ul className="flex gap-2 flex-col">
              <li>
                <a
                  href="https://jlpradosc.com/a-slimmer-me/"
                  className="flex gap-2 items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ArrowRight className="fill-white h-4 w-auto" />
                  <span>{t('bariatricSurgery')}</span>
                </a>
              </li>
              <li>
                <a
                  href="https://jlpradosc.com/a-beautiful-me/"
                  className="flex gap-2 items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ArrowRight className="fill-white h-4 w-auto" />
                  <span>{t('plasticSurgery')}</span>
                </a>
              </li>
              <li>
                <a
                  href="https://jlpradosc.com/general-surgery/"
                  className="flex gap-2 items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ArrowRight className="fill-white h-4 w-auto" />
                  <span>{t('generalSurgery')}</span>
                </a>
              </li>
              <li>
                <a
                  href="https://jlpradosc.com/a-smiling-me/"
                  className="flex gap-2 items-center"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ArrowRight className="fill-white h-4 w-auto" />
                  <span>{t('dental')}</span>
                </a>
              </li>
              <li>
                <a
                  href="https://jlpradosc.com/"
                  className="flex gap-2 items-center"
                >
                  <ArrowRight className="fill-white h-4 w-auto" />
                  <span>{t('cancellationPolicy')}</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/2">
            <h4 className="font-bold mb-2 tracking-wider">{t('Contact')}</h4>
            <ul className="flex gap-2 flex-col">
              <li>
                <a href="tel:+16192408735" className="flex gap-2 items-center">
                  <Phone className="fill-white h-4 w-auto" />
                  <span>+1 (619) 240-8735</span>
                </a>
              </li>
              <li>
                <a href="https://api.whatsapp.com/send?phone=16192408735" className="flex gap-2 items-center">
                  <Whatsapp className="fill-white h-4 w-auto" />
                  <span>+1 (619) 240-8735</span>
                </a>
              </li>
              <li>
                <a href="mailto:info@jlpradosc.com" className="flex gap-2 items-center">
                  <Mail className="fill-white h-4 w-auto" />
                  <span>
                    info@jlpradosc.com
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div aria-label="social-media" className="border-t-[1px] border-[#FFFFFF26] pb-7">
        <div className="flex justify-center items-center bg-[#061939] text-white">
          <div className="p-4">
            <a
              href="https://www.facebook.com/jlpradosurgicalcenter/"
              target="_blank"
              rel="noreferrer"
              className="flex gap-2 items-center"
            >
              <Instagram className="fill-white h-5 w-auto" />
              <span className="hidden md:block">INSTAGRAM</span>
            </a>
          </div>
          <div className="p-4 flex items-center gap-2">
            <a
              href="https://www.instagram.com/jlprado_surgicalcenter/"
              target="_blank"
              rel="noreferrer"
              className="flex gap-2 items-center"
            >
              <Facebook className="fill-white h-5 w-auto" />
              <span className="hidden md:block">FACEBOOK</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
