import { stepsEnum } from 'utils/enums';
import { useTranslation } from 'react-i18next';

const SepsControls = ({ step, handleBackStep, handleNextStep }) => {
  const { t } = useTranslation('global');
  return (
    <div className="mt-10 w-full">
      <div className={`flex ${step === stepsEnum.GENERAL
        ? 'justify-end' : 'justify-between'}`}
      >
        {step !== stepsEnum.GENERAL && (
        <button
          type="button"
          className="bg-primary-100 text-[#fff] disabled:opacity-50 h-auto p-4 rounded-lg font-semibold"
          onClick={handleBackStep}
        >
          {t('back')}
        </button>
        )}
        <button
          type="submit"
          className="bg-primary-100 text-[#fff] disabled:opacity-50 h-auto p-4 rounded-lg font-semibold"
          onClick={() => handleNextStep(1)}
        >
          {t('next')}
        </button>
      </div>
    </div>
  );
};

export default SepsControls;
