/* eslint-disable no-param-reassign */
import { useContext, useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { applicationSchema } from 'utils/validations/generalFormSchema';
import Input from 'components/Input';
import Select from 'components/Select';
import { Space } from 'components/Space';
import Container from 'components/Container';
import Title from 'components/Title';
import PlasticSurgery from 'components/DinamicFields/PlasticSurgery';
import Dental from 'components/DinamicFields/Dental';
import {
  genderOptions,
  servicesEnum,
  trueFalseOptions,
} from 'utils/enums';
import { useTranslation } from 'react-i18next';
import Oncology from 'components/DinamicFields/Oncology';
import { getProcedures, getServices } from 'utils/api/requests';
import Dragger from 'components/Dragger';
import { FilesContext } from 'contexts/Files';

const formName = 'FORM_GENERAL_VALUES';

const Formulario = ({ children }) => {
  const [services, setServices] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [packages, setPackages] = useState([]);
  const { setFiles } = useContext(FilesContext);
  const { t } = useTranslation('global');
  const methods = useForm({
    resolver: zodResolver(applicationSchema()),
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldUnregister: true,
  });

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
    resetField,
    clearErrors,
  } = methods;

  const formValues = watch();

  const filterProcedures = async (serviceId, gender) => {
    const _procedures = await getProcedures({ serviceId });
    const filtered = _procedures.filter((procedure) => procedure.gender.includes(gender));
    setProcedures([
      {
        value: undefined,
        label_es: 'Seleccione una opción',
        label_en: 'Select an option',
      },
      ...filtered,
    ]);
  };

  const setValues = async () => {
    const beforeValues = JSON.parse(localStorage.getItem(formName)) || {};
    const keys = Object.keys(beforeValues);
    keys.forEach((key) => {
      setValue(key, beforeValues[key]);
      if (key === 'service') {
        setTimeout(() => {
          setValue(key, beforeValues.service);
        }, 500);
      }
    });
    if (beforeValues.service && beforeValues.gender) {
      await filterProcedures(beforeValues.service, beforeValues.gender);
    }
  };

  const fetchData = async () => {
    const _services = await getServices();
    setServices(_services);
  };

  // Set values from localstorage
  useEffect(() => {
    setValues();
    fetchData();
  }, []);

  // Save values in localstorage
  useEffect(() => {
    const saveValues = async () => {
      const values = { ...formValues };
      localStorage.setItem(formName, JSON.stringify({ ...values }));
    };
    saveValues();
  }, [formValues]);

  const onChange = async (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (id === 'service') {
      setFiles({});
      resetField('procedure');
      await filterProcedures(value, formValues.gender);
    }
    if (id === 'gender' && formValues.service) {
      resetField('procedure');
      await filterProcedures(formValues.service, value);
    }
    if (id === 'procedure') {
      resetField('package');
      const match = procedures.find((procedure) => procedure.id === Number(value));
      if (match?.packages?.length) {
        setPackages([
          {
            value: undefined,
            label_es: 'Seleccione una opción',
            label_en: 'Select an option',
          },
          ...match.packages.map((pack) => ({
            value: pack.id,
            label_es: pack.name_es,
            label_en: pack.name_en,
          })),
        ]);
      } else {
        setPackages([]);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <Title
        text={t('title3')}
        level={3}
      />
      <form
        className="mb-14"
        onChange={onChange}
        onSubmit={handleSubmit(() => {})}
      >
        <Container>
          <Select
            label={t('patient.beforeTreatment')}
            id="beforeTreatment"
            options={trueFalseOptions}
            error={errors?.beforeTreatment}
            register={register}
          />
          <Input
            label={t('patient.name')}
            type="text"
            id="name"
            error={errors?.name}
            register={register}
            required
          />
          <Input
            label={t('patient.lastName')}
            type="text"
            id="lastName"
            error={errors?.lastName}
            register={register}
            required
          />
          <Select
            label={t('patient.gender')}
            id="gender"
            options={genderOptions}
            error={errors.gender}
            register={register}
            required
          />
          <Input
            label={t('patient.dateOfBirth')}
            type="date"
            id="birthdate"
            error={errors.birthdate}
            register={register}
            required
            format="dd/MM/yyyy"
          />
          <Space />
          <Input
            label={t('patient.phone1')}
            type="tel"
            id="phone"
            error={errors.phone}
            register={register}
            required
            maxLength={10}
          />
          <Input
            label={t('patient.phone2')}
            type="tel"
            id="phone2"
            error={errors.phone2}
            register={register}
            maxLength={10}
          />
          <Input
            label={t('patient.email')}
            type="email"
            id="email"
            error={errors.email}
            register={register}
            required
          />
          <Input
            label={t('patient.address')}
            type="text"
            id="address"
            error={errors.address}
            register={register}
          />
          <Input
            label={t('patient.zip')}
            type="text"
            id="zipCode"
            error={errors.zipCode}
            register={register}
          />
          <Input
            label={t('patient.city')}
            type="text"
            id="city"
            error={errors.city}
            register={register}
          />
          <Input
            label={t('patient.state')}
            type="text"
            id="state"
            error={errors.state}
            register={register}
            required
          />
          <Input
            label={t('patient.country')}
            type="text"
            id="country"
            error={errors.country}
            register={register}
            required
          />
          <Space />
          <Select
            label={t('service')}
            id="service"
            options={services}
            error={errors.service}
            register={register}
            required
          />
          {procedures.length > 1 && (
            <Select
              label={t('procedure')}
              id="procedure"
              options={procedures}
              error={errors.procedure}
              register={register}
              required
            />
          )}
          {packages.length > 0 && (
            <Select
              label={t('package')}
              id="package"
              options={packages}
              error={errors.package}
              register={register}
              required
            />
          )}
        </Container>
        {(formValues.service === servicesEnum.UROLOGY
        && (formValues.procedure === '1' || formValues.procedure === '7')) && (
          <Dragger
            id="urologyPhoto"
            label={t('extraPlasticSurgeryPhoto')}
            error={errors.urologyPhoto}
            register={register}
            required
            clearErrors={clearErrors}
          />
        )}
        {formValues.service === servicesEnum.PLASTIC_SURGERY && (
        <PlasticSurgery
          errors={errors}
          register={register}
          resetField={resetField}
          formValues={formValues}
          clearErrors={clearErrors}
        />
        )}
        {formValues.service === servicesEnum.DENTAL && (
        <Dental
          register={register}
          errors={errors}
          formValues={formValues}
          resetField={resetField}
          clearErrors={clearErrors}
        />
        )}
        {formValues.service === servicesEnum.ONCOLOGY && (
        <Oncology
          register={register}
          errors={errors}
          formValues={formValues}
          resetField={resetField}
        />
        )}
        <div className="mt-4">
          <Title
            text={t('emergencyContact')}
            level={4}
          />
        </div>
        <Container>
          <Input
            label={t('emergencyContactName')}
            type="text"
            id="contactName"
            error={errors?.contactName}
            register={register}
            required
          />
          <Input
            label={t('emergencyContactPhone')}
            type="tel"
            id="contactPhone"
            error={errors?.contactPhone}
            register={register}
            required
            maxLength={10}
          />
          <Input
            label={t('emergencyContactRelation')}
            type="text"
            id="contactRelation"
            error={errors?.contactRelation}
            register={register}
            required
          />
        </Container>
        {children}
      </form>
    </FormProvider>
  );
};

export default Formulario;
