import { useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Select from 'components/Select';
import { metricSystemOptions, trueFalseOptions } from 'utils/enums';
import { healthDetailsFormSchema } from 'utils/validations/HealthDetailsFormSchema';
import Container from 'components/Container';
import Input from 'components/Input';
import Title from 'components/Title';
import Plus from 'components/icons/Plus';
import Minus from 'components/icons/Minus';
import { useTranslation } from 'react-i18next';

export const FORM_NAME = 'DETALLES_DE_SALUD_FORM_VALUES';

const DetallesDeSalud = ({ children }) => {
  const { t } = useTranslation('global');
  const {
    handleSubmit,
    watch,
    setValue,
    register,
    control,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: zodResolver(healthDetailsFormSchema()),
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldUnregister: true,
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'medications',
  });

  const formValues = watch();

  // Set values from localstorage
  useEffect(() => {
    const beforeValues = JSON.parse(localStorage.getItem(FORM_NAME)) || {};
    const keys = Object.keys(beforeValues);
    keys.forEach((key) => {
      setTimeout(() => setValue(key, beforeValues[key]), 0);
      if (key === 'medications' && beforeValues?.medications?.length) update(beforeValues[key]);
    });
    setTimeout(() => setFocus('measurementSystem'), 100);
  }, []);

  // Save values in localstorage
  useEffect(() => {
    const saveValues = () => {
      localStorage.setItem(FORM_NAME, JSON.stringify({ ...formValues }));
    };
    saveValues();
  }, [formValues]);

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === 'takesMedications' && value === 'true') {
      append({
        name: '',
        frequency: '',
        dose: '',
        reason: '',
      });
    }
  };

  return (
    <form
      className="mb-14"
      onChange={onChange}
      onSubmit={handleSubmit(() => {})}
    >
      <Container>
        <Select
          label={t('measurementSystem')}
          register={register}
          id="measurementSystem"
          options={metricSystemOptions}
          error={errors?.measurementSystem}
          required
        />
        {formValues.measurementSystem !== 'Seleccione una opción' && (
          <>
            <Input
              label={`${t('maxWeight')} (${formValues.measurementSystem === '1' ? 'Kg' : 'Lb'})`}
              id="maxWeight"
              type="number"
              register={register}
              error={errors?.maxWeight}
              required
              min="1"
            />
            <Input
              label={`${t('currentWeight')} (${formValues.measurementSystem === '1' ? 'Kg' : 'Lb'})`}
              id="currentWeight"
              type="number"
              register={register}
              error={errors?.currentWeight}
              required
              min="1"
            />
            <Input
              label={`${t('height')} (${formValues.measurementSystem === '1' ? 'Cm' : 'In'})`}
              id="height"
              type="number"
              register={register}
              error={errors?.height}
              required
              min="1"
            />
          </>
        )}
      </Container>
      <Container>
        <Select
          label={t('takesMedications')}
          id="takesMedications"
          options={trueFalseOptions}
          error={errors?.takesMedications}
          register={register}
          required
        />
      </Container>
      {formValues?.takesMedications === 'true' && fields.map((field, index) => (
        <div key={field.id} className="grid-cols-1 grid md:grid-flow-row-dense md:grid-cols-12 grid-rows-1 gap-x-4 hover:grid-flow-row border-slate-200 border-solid border-2 rounded-lg p-5 mb-3">
          <div className="col-span-3">
            <Input
              label={t('medicine')}
              id={`medications[${index}].name`}
              type="text"
              register={register}
              error={errors?.medications?.[index]?.name}
              required
            />
          </div>
          <div className="col-span-3">
            <Input
              label={t('frequency')}
              id={`medications[${index}].frequency`}
              type="text"
              register={register}
              error={errors?.medications?.[index]?.frequency}
              required
            />
          </div>
          <div className="col-span-3 md:col-span-2">
            <Input
              label={t('dosage')}
              id={`medications[${index}].dose`}
              type="text"
              register={register}
              error={errors?.medications?.[index]?.dose}
              required
            />
          </div>
          <div className="col-span-3">
            <Input
              label={t('reason')}
              id={`medications[${index}].reason`}
              type="text"
              register={register}
              error={errors?.medications?.[index]?.reason}
              required
            />
          </div>
          <div className="flex col-span-1 md:justify-end justify-center">
            {index === fields.length - 1 && (
              <button
                type="button"
                className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                onClick={() => append({})}
                aria-label="Add medication"
              >
                <Plus />
              </button>
            )}
            {index !== fields.length - 1 && (
              <button
                type="button"
                className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                onClick={() => remove(index)}
                aria-label="Remove medication"
              >
                <Minus />
              </button>
            )}
          </div>
        </div>

      ))}
      <Container>
        <Select
          label={t('refluxProblems')}
          id="refluxProblems"
          options={trueFalseOptions}
          error={errors?.refluxProblems}
          register={register}
          required
        />
        <Select
          label={t('anticoagulants')}
          id="anticoagulants"
          options={trueFalseOptions}
          error={errors?.anticoagulants}
          register={register}
          required
        />
        {formValues?.anticoagulants === 'true' && (
          <Input
            label={t('anticoagulantsReason')}
            id="anticoagulantsReason"
            type="text"
            register={register}
            error={errors?.anticoagulantsReason}
            required
          />
        )}
      </Container>
      <Title text={t('allergiesTitle')} level={4} />
      <Container>
        <Select
          label={t('penicillin')}
          id="penicillin"
          options={trueFalseOptions}
          error={errors?.penicillin}
          register={register}
          required
        />
        <Select
          label={t('sulfasDrugs')}
          id="sulfasDrugs"
          options={trueFalseOptions}
          error={errors?.sulfasDrugs}
          register={register}
          required
        />
        <Select
          label={t('iodine')}
          id="iodine"
          options={trueFalseOptions}
          error={errors?.iodine}
          register={register}
          required
        />
        <Select
          label={t('tape')}
          id="tape"
          options={trueFalseOptions}
          error={errors?.tape}
          register={register}
          required
        />
        <Select
          label={t('latex')}
          id="latex"
          options={trueFalseOptions}
          error={errors?.latex}
          register={register}
          required
        />
        <Select
          label={t('aspirin')}
          id="aspirin"
          options={trueFalseOptions}
          error={errors?.aspirin}
          register={register}
          required
        />
        <Input
          label={t('otherAllergies')}
          id="otherAllergies"
          type="text"
          register={register}
          error={errors?.otherAllergies}
          placeholder={t('referenceOtro')}
        />
      </Container>
      {children}
    </form>
  );
};

export default DetallesDeSalud;
