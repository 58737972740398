import React from 'react';
import { steps } from 'utils/enums';
import Check from './icons/Check';
import { getCurrentLanguage } from './ToggleLanguege';

const Steps = ({ currentStep }) => {
  const lang = getCurrentLanguage();

  return (
    <div className="mt-8">
      <ol className="flex flex-col gap-1 flex-wrap lg:flex-nowrap sm:flex-row items-baseline md:justify-between sm:items-center w-full text-sm font-medium text-center text-gray-500 ">
        {steps.map((step, index) => {
          const curr = index + 1;
          const stepText = step?.[`text_${lang}`];
          const active = currentStep === stepText;
          const isDone = steps.findIndex((s) => s?.[`text_${lang}`] === currentStep) > index;
          const activeClass = active ? 'text-primary-200 dark:text-primary-300' : '';
          const stepClassName = `flex xs:w-44 lg:w-full items-center ${activeClass} place-content-center `;

          return (
            <li
              className={stepClassName}
              key={step.text_en}
            >
              {isDone ? (
                <React.Fragment key={stepText}>
                  <span className="flex items-center text-green-600 ml-4 ">
                    <Check />
                  </span>
                  <div>{stepText}</div>
                </React.Fragment>
              ) : (
                <React.Fragment key={stepText}>
                  <span className="flex items-center ml-4">
                    {currentStep === stepText ? (
                      <span className="me-3 w-8 h-8 bg-slate-500 rounded-full text-[#fff] flex items-center justify-center">
                        {curr}
                      </span>
                    ) : (
                      <span className="me-3 w-8 h-8 bg-slate-500 rounded-full text-[#fff] flex items-center justify-center">
                        {curr}
                      </span>
                    )}
                    {stepText}
                  </span>
                </React.Fragment>
              )}
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default Steps;
