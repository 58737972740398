const reset = () => {
  localStorage.removeItem('FORM_VALUES_HISTORIAL_QUIRURGICO');
  localStorage.removeItem('FORM_VALUES_HISTORIAL_MEDICO');
  localStorage.removeItem('DETALLES_DE_SALUD_FORM_VALUES');
  localStorage.removeItem('FORM_VALUES_SALUD_GENERAL');
  localStorage.removeItem('FORM_GENERAL_VALUES');
  localStorage.removeItem('REFERENCIAS');
  localStorage.removeItem('CLIENT_ID');
  window.location.reload();
};
export default reset;
