import { z } from 'zod';
import { getCurrentLanguage } from 'components/ToggleLanguege';

export const surgeryHistorySchema = () => {
  const lenguage = getCurrentLanguage();
  const isSpanish = lenguage === 'es';
  const requiredMessage = isSpanish ? 'Este campo es requerido' : 'This field is required';

  const handleEnumError = (issue) => {
    if (issue.code === z.ZodIssueCode.invalid_enum_value) {
      return { message: requiredMessage };
    }
    if (issue.code === z.ZodIssueCode.invalid_type) {
      return { message: requiredMessage };
    }
    return { message: issue.message ?? '' };
  };

  const trueFalseSchema = z.enum(['true', 'false'], { errorMap: handleEnumError });

  return z.object({
    hasBeenSurgery: trueFalseSchema,
    previousSurgeries: z.array(z.object({
      wich: z.string().min(1, { message: requiredMessage }).optional(),
      age: z.string().min(1, { message: requiredMessage }).optional(),
      date: z
        .string()
        .min(0, { message: requiredMessage })
        .refine((value) => {
          const formValues = JSON.parse(localStorage.getItem('FORM_VALUES_HISTORIAL_QUIRURGICO')) || {};
          if (formValues.hasBeenSurgery === 'false') {
            return true;
          }
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        }, { message: requiredMessage }).optional(),
      complications: z.string().min(1, { message: requiredMessage }).optional(),
    })).optional(),
    prostateSurgeryBefore: trueFalseSchema.optional(),
    previousProstateSurgeries: z.array(z.object({
      type: z.string().min(0, { message: requiredMessage }).optional(),
      date: z
        .string()
        .min(0, { message: requiredMessage })
        .refine((value) => {
          const formValues = JSON.parse(localStorage.getItem('FORM_VALUES_HISTORIAL_QUIRURGICO')) || {};
          if (!formValues.prostateSurgeryBefore || formValues.prostateSurgeryBefore === 'false') {
            return true;
          }
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        }, { message: requiredMessage }).optional(),
    })).optional(),
    pelvicSurgeryBefore: trueFalseSchema.optional(),
    previousPelvicSurgeries: z.array(z.object({
      type: z.string().min(0, { message: requiredMessage }).optional(),
      date: z
        .string()
        .min(0, { message: requiredMessage })
        .refine((value) => {
          const formValues = JSON.parse(localStorage.getItem('FORM_VALUES_HISTORIAL_QUIRURGICO')) || {};
          if (!formValues?.pelvicSurgeryBefore || formValues?.pelvicSurgeryBefore === 'false') {
            return true;
          }
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        }, { message: requiredMessage }).optional(),
    })).optional(),
    bariatricSurgeryBefore: trueFalseSchema.optional(),
    anestheticProblems: trueFalseSchema.optional(),
    anestheticProblemsDescription: z.string().min(1, { message: requiredMessage }).optional(),
    previousBariatricSurgeries: z.array(z.object({
      wich: z.string().min(0, { message: requiredMessage }).optional(),
      age: z.string().min(0, { message: requiredMessage }).optional(),
      date: z
        .string()
        .min(0, { message: requiredMessage })
        .refine((value) => {
          const formValues = JSON.parse(localStorage.getItem('FORM_VALUES_HISTORIAL_QUIRURGICO')) || {};
          if (!formValues?.bariatricSurgeryBefore || formValues?.bariatricSurgeryBefore === 'false') {
            return true;
          }
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        }, { message: requiredMessage }).optional(),
      complications: z.string().min(0, { message: requiredMessage }).optional(),
    })).optional(),
    plasticSurgeryBefore: trueFalseSchema.optional(),
    previousPlasticSurgeries: z.array(z.object({
      type: z.string().min(0, { message: requiredMessage }).optional(),
      date: z
        .string()
        .min(0, { message: requiredMessage })
        .refine((value) => {
          const formValues = JSON.parse(localStorage.getItem('FORM_VALUES_HISTORIAL_QUIRURGICO')) || {};
          if (!formValues?.plasticSurgeryBefore || formValues?.plasticSurgeryBefore === 'false') {
            return true;
          }
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        }, { message: requiredMessage }).optional(),
    })).optional(),
    vasectomyDate: z
      .string()
      .min(0, { message: requiredMessage })
      .refine((value) => {
        const formGeneralValues = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES')) || {};
        if (!formGeneralValues?.urologyMaleProcedures === '3') {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        }
        return true;
      }, { message: requiredMessage }).optional(),
    vasectomyPlace: z.string().min(0, { message: requiredMessage }).optional(),
    vasectomyComplications: trueFalseSchema.optional(),
    vasectomyComplicationsDescription: z.string().min(0, { message: requiredMessage }).optional(),
    testiclePain: trueFalseSchema.optional(),
    testiclePainDescription: z.string().min(0, { message: requiredMessage }).optional(),
    testicleSurgery: trueFalseSchema.optional(),
    testicleSurgeriesList: z.array(z.object({
      date: z
        .string()
        .min(0, { message: requiredMessage })
        .refine((value) => {
          const formValues = JSON.parse(localStorage.getItem('FORM_VALUES_HISTORIAL_QUIRURGICO')) || {};
          if (!formValues?.testicleSurgery || formValues?.testicleSurgery === 'false') {
            return true;
          }
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        }, { message: requiredMessage }).optional(),
      complications: z.string().min(0, { message: requiredMessage }).optional(),
    })).optional(),
  });
};
