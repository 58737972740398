import { forwardRef } from 'react';
import { getCurrentLanguage } from './ToggleLanguege';

const Select = forwardRef(({
  label,
  id,
  options,
  error,
  register,
  required,
  col = 1,
  ...rest
}, ref) => {
  const lang = getCurrentLanguage();
  return (
    <div className={`mt-1 col-span-${col}`}>
      <label
        htmlFor={id}
        className="block text-md font-semibold text-pretty"
      >
        {label}
        {required && <span className="text-red ml-1">*</span>}
      </label>
      <select
        ref={ref}
        id={id}
        {...register(id)}
        className={`
        bg-slate-100
        w-full p-4 mt-2 border rounded-md outline-none
        focus:ring-1 border-primary-300 focus:border-primary-500 focus:ring-primary-500 focus:ring-opacity-50
        ${error ? 'border-red focus:border-red focus:ring-red' : 'border-primary-300'}
      `}
        defaultValue={null}
        {...rest}
      >
        {options.map((option) => (
          <option
            key={option.label || option.label_en}
            value={option.value}
          >
            {option?.[`label_${lang}`] || option?.[`name_${lang}`] || option.label}
          </option>
        ))}
      </select>
      <p className="text-red h-[21px] mb-1">{error?.message}</p>
    </div>
  );
});

export default Select;
