const ArrowDown = () => (
  <svg
    className="w-2.5 h-2.5 ms-2.5"
    aria-hidden="true"
    fill="none"
    viewBox="0 0 10 6"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m1 1 4 4 4-4"
    />
  </svg>
);

export default ArrowDown;
