const Hamburger = () => (
  <svg
    className="w-5 h-5"
    aria-hidden="true"
    fill="none"
    viewBox="0 0 17 14"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M1 1h15M1 7h15M1 13h15"
    />
  </svg>
);

export default Hamburger;
