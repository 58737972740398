import { z } from 'zod';
import { getCurrentLanguage } from 'components/ToggleLanguege';

export const generalHealthSchema = () => {
  const lenguage = getCurrentLanguage();
  const isSpanish = lenguage === 'es';
  const requiredMessage = isSpanish ? 'Este campo es requerido' : 'This field is required';

  const handleEnumError = (issue) => {
    if (issue.code === z.ZodIssueCode.invalid_enum_value) {
      return { message: requiredMessage };
    }
    if (issue.code === z.ZodIssueCode.invalid_type) {
      return { message: requiredMessage };
    }
    return { message: issue.message ?? '' };
  };

  const trueFalseSchema = z.enum(['true', 'false'], { errorMap: handleEnumError });

  return z.object({
    actuallySmokes: trueFalseSchema,
    cigarettesPerDay: z.string().min(1, requiredMessage).optional(),
    yearsSmoking: z.string().min(1, requiredMessage).optional(),
    triedToQuit: trueFalseSchema.optional(),
    quitTime: z.string().min(1, requiredMessage).optional(),
    actuallyVapes: trueFalseSchema,
    actuallyDrinks: trueFalseSchema,
    drinksFrequency: z.string().min(1, requiredMessage).optional(),
    actuallyDrugs: trueFalseSchema,
    drugsFrequency: z.string().min(1, requiredMessage).optional(),
    intravenousDrugs: trueFalseSchema.optional(),
    hasAsthma: trueFalseSchema,
    usingBiPAP: trueFalseSchema,
    doesExercise: trueFalseSchema,
    exercises: z.array(z.object({
      name: z.string().min(1, requiredMessage).optional(),
      since: z.string().min(1, requiredMessage).optional(),
      frequency: z.string().min(1, requiredMessage).optional(),
      time: z.string().min(1, requiredMessage).optional(),
    })).optional(),
    sleepHours: z.string().min(1, requiredMessage).optional(),
    takesSleepingPills: trueFalseSchema.optional(),
    anxietyDepression: trueFalseSchema.optional(),
    takesAnxietyDepressionPills: trueFalseSchema.optional(),
    feelsStressed: trueFalseSchema.optional(),
    morningErections: trueFalseSchema.optional(),
    morningErectionsAmount: z.string().min(1, requiredMessage).optional(),
    erectionProblems: trueFalseSchema.optional(),
    erectionProblemsSince: z.string().min(1, requiredMessage).optional(),
    erectionProblemsDescription: z.string().min(1, requiredMessage).optional(),
    maintainErectionProblems: trueFalseSchema.optional(),
    takesViagra: trueFalseSchema.optional(),
    takesViagraSince: z
      .string()
      .min(0, { message: requiredMessage })
      .refine((value) => {
        const formValues = JSON.parse(localStorage.getItem('FORM_VALUES_SALUD_GENERAL')) || {};
        if (formValues?.takesViagra === 'true') {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        }
        return true;
      }, { message: requiredMessage }).optional(),
    viagraEffectiveness: z.string().min(1, requiredMessage).optional(),
    viagraFrequency: z.string().min(1, requiredMessage).optional(),
    viagraHowToTake: z.string().min(1, requiredMessage).optional(),
    takesNaturalRemedies: trueFalseSchema.optional(),
    naturalRemediesType: z.string().min(1, requiredMessage).optional(),
    naturalRemediesEffectiveness: z.string().min(1, requiredMessage).optional(),
    naturalRemediesWhereToGet: z.string().min(1, requiredMessage).optional(),
    injectedMedication: trueFalseSchema.optional(),
    injectedMedicationTimes: z.string().min(1, requiredMessage).optional(),
    injectedMedicationEffectiveness: z.string().min(1, requiredMessage).optional(),
    erectionMoreThan6Hours: trueFalseSchema.optional(),
    erectionMoreThan6HoursSince: z.string().min(1, requiredMessage).optional(),
    erectionMoreThan6HoursResolution: z.string().min(1, requiredMessage).optional(),
    erectionMoreThan6HoursMedicalAttention: trueFalseSchema.optional(),
    penileCurvature: trueFalseSchema.optional(),
    penileCurvatureIntensity: z.string().min(1, requiredMessage).optional(),
    penileCurvatureDirection: z.string().min(1, requiredMessage).optional(),
    penileCurvaturePain: trueFalseSchema.optional(),
    penileCurvatureCoitus: trueFalseSchema.optional(),
    prpInjected: trueFalseSchema.optional(),
    stemCellsTreatment: trueFalseSchema.optional(),
    vascularRegenerationTherapy: trueFalseSchema.optional(),
    prostateCancerFamily: trueFalseSchema.optional(),
    psaTest: trueFalseSchema.optional(),
    psaTestDate: z
      .string()
      .min(0, { message: requiredMessage })
      .refine((value) => {
        const formValues = JSON.parse(localStorage.getItem('FORM_VALUES_SALUD_GENERAL')) || {};
        if (formValues?.psaTest === 'true') {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        }
        return true;
      }, { message: requiredMessage }).optional(),
    psaTestResult: z.string().min(1, requiredMessage).optional(),
    prostateBiopsy: trueFalseSchema.optional(),
    prostateBiopsyDate: z
      .string()
      .min(0, { message: requiredMessage })
      .refine((value) => {
        const formValues = JSON.parse(localStorage.getItem('FORM_VALUES_SALUD_GENERAL')) || {};
        if (formValues?.prostateBiopsy === 'true') {
          const date = new Date(value);
          return !Number.isNaN(date.getTime());
        }
        return true;
      }, { message: requiredMessage }).optional(),
    prostateBiopsyResult: z.string().min(1, requiredMessage).optional(),
    nightlyUrination: trueFalseSchema.optional(),
    nightlyUrinationTimes: z.string().min(1, requiredMessage).optional(),
    dripsAfterUrination: trueFalseSchema.optional(),
    frequentUrination: trueFalseSchema.optional(),
    slowUrination: trueFalseSchema.optional(),
    frequentPause: trueFalseSchema.optional(),
    weakStream: trueFalseSchema.optional(),
    strains: trueFalseSchema.optional(),
    bled: trueFalseSchema.optional(),
    urinaryCatheter: trueFalseSchema.optional(),
    urinaryCatheterTime: z.string().min(1, requiredMessage).optional(),
    urinaryInfections: trueFalseSchema.optional(),
    urinaryInfectionsAntibiotics: z.string().min(1, requiredMessage).optional(),
    urinaryIncontinence: trueFalseSchema.optional(),
    urinaryIncontinenceSince: z.string().min(1, requiredMessage).optional(),
    takesBPHMedications: trueFalseSchema.optional(),
    bphMedications: z.string().min(1, requiredMessage).optional(),
    bphMedicationsSince: z.string().min(1, requiredMessage).optional(),
  });
};
