import Container from 'components/Container';
import Dragger from 'components/Dragger';
import Select from 'components/Select';
import { getCurrentLanguage } from 'components/ToggleLanguege';
import { useTranslation } from 'react-i18next';
import { extraPlasticSurgeryOptions } from 'utils/enums';

const PlasticSurgery = ({
  errors,
  register,
  resetField,
  formValues,
  clearErrors,
}) => {
  const { t } = useTranslation('global');
  const lang = getCurrentLanguage();
  const isSpanish = lang === 'es';
  const defaultLabel = isSpanish ? 'Seleccione una opción' : 'Select an option';

  return (
    <>
      <p className="text-slate-600 mb-3">
        {t('advice')}
      </p>
      <div className="grid gap-x-4 grid-cols-1 md:grid-cols-2">
        <Dragger
          register={register}
          id="frontPhoto"
          label={t('frontPhoto')}
          required
          error={errors.frontPhoto}
          resetField={resetField}
          clearErrors={clearErrors}
        />
        <Dragger
          register={register}
          id="backPhoto"
          label={t('backPhoto')}
          required
          error={errors.backPhoto}
          resetField={resetField}
          clearErrors={clearErrors}
        />
        <Dragger
          register={register}
          id="leftPhoto"
          label={t('leftPhoto')}
          required
          error={errors.leftPhoto}
          resetField={resetField}
          clearErrors={clearErrors}
        />
        <Dragger
          register={register}
          id="rigthPhoto"
          label={t('rigthPhoto')}
          required
          error={errors.rigthPhoto}
          resetField={resetField}
          clearErrors={clearErrors}
        />
      </div>
      <Container>
        <Select
          label={t('extraPlasticSurgery')}
          id="extraPlasticSurgery"
          options={extraPlasticSurgeryOptions}
          error={errors.extraPlasticSurgery}
          register={register}
        />
      </Container>
      {formValues.extraPlasticSurgery !== defaultLabel && (
        <div className="grid gap-x-4 grid-cols-1 md:grid-cols-2">
          <Dragger
            register={register}
            id="extraPlasticSurgeryPhoto"
            label={t('extraPlasticSurgeryPhoto')}
            required
            error={errors.extraPlasticSurgeryPhoto}
            resetField={resetField}
          />
        </div>
      )}
    </>
  );
};

export default PlasticSurgery;
