import { createContext, useState, useMemo } from 'react';

export const FilesContext = createContext();

export const FilesProvider = ({ children }) => {
  const [files, setFiles] = useState({});

  const value = useMemo(() => ({ files, setFiles }), [files, setFiles]);
  return (
    <FilesContext.Provider value={value}>
      {children}
    </FilesContext.Provider>
  );
};
