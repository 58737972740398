import React, { useEffect, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { sendAppointment } from 'utils/api/requests';
import { FilesContext } from 'contexts/Files';
import { useTranslation } from 'react-i18next';
import Title from './Title';
import Input from './Input';

const ModalConfirm = ({ visible, setVisible }) => {
  const { t } = useTranslation('global');
  const { files } = useContext(FilesContext);
  const formRef = useRef(null);
  const debounce = useRef(null);
  const { register, handleSubmit } = useForm({
    shouldUnregister: true,
  });

  useEffect(() => {
    const overflow = visible ? 'hidden' : 'auto';
    document.body.style.overflow = overflow;
  }, [visible]);

  if (!visible) return null;

  const onSubmit = async (data) => {
    if (debounce.current) {
      clearTimeout(debounce.current);
    }
    debounce.current = setTimeout(async () => {
      await sendAppointment({ ...data, files });
    }, 500);
  };

  return (
    <div
      id="default-modal"
      tabIndex={-1}
      aria-hidden="true"
      role="dialog"
      className="flex overflow-y-auto overflow-x-hidden active:ring-0 fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full content-center bg-black bg-opacity-50 "
    >
      <div className="flex relative p-4 w-full max-w-2xl max-h-full">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow border">
          {/* Modal header */}
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border border-solid ">
            <Title text={t('termns')} level={3} />
          </div>
          {/* Modal body */}
          <div className="p-4 md:p-5 space-y-4">
            <p className="text-base leading-relaxed text-gray-500">
              {t('confirmP1')}
            </p>
            <p className="text-base leading-relaxed text-gray-500">
              {t('confirmP2')}
            </p>
            <p className="text-base leading-relaxed text-gray-500">
              {t('confirmP3')}
            </p>
            <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
              <Input
                id="code"
                label={t('code')}
                register={register}
                type="text"
              />
            </form>
          </div>
          {/* Modal footer */}
          <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b justify-end gap-3">
            <button
              data-modal-hide="default-modal"
              type="button"
              className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-100 focus:z-10 focus:ring-4 focus:ring-gray-100"
              onClick={() => setVisible(false)}
            >
              {t('cancel')}
            </button>
            <button
              data-modal-hide="default-modal"
              type="button"
              className="text-white bg-primary-100 hover:opacity-70 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={() => formRef.current.requestSubmit()}
            >
              {t('send')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirm;
