import { zodResolver } from '@hookform/resolvers/zod';
import Container from 'components/Container';
import Input from 'components/Input';
import Select from 'components/Select';
import Title from 'components/Title';
import Minus from 'components/icons/Minus';
import Plus from 'components/icons/Plus';
import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { sangradoOptions, trueFalseOptions } from 'utils/enums';
import { medicalHistorySchema } from 'utils/validations/MedicalHistorySchema';

const FORM_NAME = 'FORM_VALUES_HISTORIAL_MEDICO';

const HistorialMedico = ({ children }) => {
  const { t } = useTranslation('global');
  const {
    handleSubmit,
    watch,
    register,
    formState: { errors },
    control,
    setValue,
    setFocus,
  } = useForm({
    resolver: zodResolver(medicalHistorySchema()),
    reValidateMode: 'onChange',
    mode: 'onChange',
    shouldUnregister: true,
  });

  // Lista de enfermedades
  const {
    fields: otherDiseasesFields,
    append: appendOtherDiseases,
    remove: removeOtherDiseases,
    update: updateOtherDiseases,
  } = useFieldArray({
    control,
    name: 'otherDiseases',
  });

  // Lista de métodos anticonceptivos
  const {
    fields: contraceptivesMethodsFields,
    append: appendContraceptivesMethods,
    remove: removeContraceptivesMethods,
    update: updateContraceptivesMethods,
  } = useFieldArray({
    control,
    name: 'contraceptivesMethods',
  });

  // Lista de hormonas
  const {
    fields: hormonesFields,
    append: appendHormones,
    remove: removeHormones,
    update: updateHormones,
  } = useFieldArray({
    control,
    name: 'wichHormones',
  });

  const formValues = watch();
  const formGeneralValues = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES')) || {};

  useEffect(() => {
    const beforeValues = JSON.parse(localStorage.getItem(FORM_NAME)) || {};
    const keys = Object.keys(beforeValues);
    keys.forEach((key) => {
      if (key === 'otherDiseases' && beforeValues?.otherDiseases?.length) updateOtherDiseases(beforeValues[key]);
      if (key === 'contraceptivesMethods' && beforeValues?.contraceptivesMethods?.length) updateContraceptivesMethods(beforeValues[key]);
      if (key === 'wichHormones' && beforeValues?.wichHormones?.length) updateHormones(beforeValues[key]);
      setTimeout(() => setValue(key, beforeValues[key]), 0);
    });
    setTimeout(() => setFocus('addictions'), 100);
  }, []);

  useEffect(() => {
    const saveValues = () => {
      localStorage.setItem(FORM_NAME, JSON.stringify({ ...formValues }));
    };
    saveValues();
  }, [formValues]);

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === 'otherDisease' && value === 'true') {
      appendOtherDiseases({
        wich: '',
        date: '',
        treatment: '',
      });
    }
    if (name === 'contraceptives' && value === 'true') {
      appendContraceptivesMethods({
        method: '',
        timeOfUse: '',
      });
    }
    if (name === 'hormones' && value === 'true') {
      appendHormones({
        type: '',
        timeOfUse: '',
      });
    }
  };

  return (
    <form
      className="mb-14"
      onChange={onChange}
      onSubmit={handleSubmit(() => {})}
    >
      <Container>
        <Select
          label={t('addictions')}
          id="addictions"
          options={trueFalseOptions}
          error={errors?.addictions}
          register={register}
          required
        />
        {/* depende de addictions */}
        {formValues.addictions === 'true' && (
          <Input
            label={t('addictionsWhich')}
            id="addictionsWhich"
            type="text"
            register={register}
            error={errors?.addictionsWhich}
            required
          />
        )}
      </Container>
      <Container>
        {/* depende de addictions */}
        <Select
          label={t('highLipids')}
          id="highLipids"
          options={trueFalseOptions}
          error={errors?.highLipids}
          register={register}
          required
        />
        {/* depende de highLipids */}
        {formValues.highLipids === 'true' && (
          <>
            <Input
              label={t('highLipidsDate')}
              id="highLipidsDate"
              register={register}
              type="date"
              error={errors?.highLipidsDate}
              required
            />
            <Input
              label={t('highLipidsTreatment')}
              id="highLipidsTreatment"
              register={register}
              type="text"
              error={errors?.highLipidsTreatment}
              required
            />
          </>
        )}
      </Container>
      <Container>
        {/* depende de highLipids */}
        <Select
          label={t('arthritis')}
          id="arthritis"
          options={trueFalseOptions}
          error={errors?.arthritis}
          register={register}
          required
        />
        {/* depende de arthritis */}
        {formValues.arthritis === 'true' && (
          <>
            <Input
              id="arthritisDate"
              label={t('arthritisDate')}
              register={register}
              type="date"
              error={errors?.arthritisDate}
              required
            />
            <Input
              label={t('arthritisTreatment')}
              id="arthritisTreatment"
              register={register}
              type="text"
              error={errors?.arthritisTreatment}
              required
            />
          </>
        )}
      </Container>
      <Container>
        {/* depende de arthritis */}
        <Select
          label={t('cholesterolAndTriglycerides')}
          id="cholesterolAndTriglycerides"
          options={trueFalseOptions}
          error={errors?.cholesterolAndTriglycerides}
          register={register}
          required
        />
        {/* depende de cholesterolAndTriglycerides */}
        {formValues.cholesterolAndTriglycerides === 'true' && (
          <>
            <Input
              id="cholesterolAndTriglyceridesDate"
              label={t('cholesterolAndTriglyceridesDate')}
              register={register}
              type="date"
              error={errors?.cholesterolAndTriglyceridesDate}
              required
            />
            <Input
              id="cholesterolAndTriglyceridesTreatment"
              label={t('cholesterolAndTriglyceridesTreatment')}
              register={register}
              type="text"
              error={errors?.cholesterolAndTriglyceridesTreatment}
              required
            />
          </>
        )}
      </Container>
      <Container>
        {/* depende de cholesterolAndTriglycerides */}
        <Select
          label={t('stroke')}
          id="stroke"
          options={trueFalseOptions}
          error={errors?.stroke}
          register={register}
          required
        />
        {/* depende de stroke */}
        {formValues.stroke === 'true' && (
          <>
            <Input
              id="strokeDate"
              label={t('strokeDate')}
              register={register}
              type="date"
              error={errors?.strokeDate}
              required
            />
            <Input
              id="strokeTreatment"
              label={t('strokeTreatment')}
              register={register}
              type="text"
              error={errors?.strokeTreatment}
              required
            />
          </>
        )}
      </Container>
      <Container>
        {/* depende de stroke */}
        <Select
          label={t('diabetes')}
          id="diabetes"
          options={trueFalseOptions}
          error={errors?.diabetes}
          register={register}
          required
        />
        {/* depende de diabetes */}
        {formValues.diabetes === 'true' && (
          <>
            <Input
              id="diabetesDate"
              label={t('diabetesDate')}
              register={register}
              type="date"
              error={errors?.diabetesDate}
              required
            />
            <Input
              label={t('diabetesTreatment')}
              id="diabetesTreatment"
              register={register}
              type="text"
              error={errors?.diabetesTreatment}
              required
            />
          </>
        )}
      </Container>
      <Container>
        {/* depende de diabetes */}
        <Select
          label={t('chronicArteryDisease')}
          id="chronicArteryDisease"
          options={trueFalseOptions}
          error={errors?.chronicArteryDisease}
          register={register}
          required
        />
        {/* depende de chronicArteryDisease */}
        {formValues.chronicArteryDisease === 'true' && (
          <>
            <Input
              label={t('chronicArteryDiseaseDate')}
              id="chronicArteryDiseaseDate"
              register={register}
              type="date"
              error={errors?.chronicArteryDiseaseDate}
              required
            />
            <Input
              id="chronicArteryDiseaseTreatment"
              label={t('chronicArteryDiseaseTreatment')}
              register={register}
              error={errors?.chronicArteryDiseaseTreatment}
              required
            />
          </>
        )}
      </Container>
      <Container>
        {/* depende de chronicArteryDisease */}
        <Select
          label={t('liverDisease')}
          id="liverDisease"
          options={trueFalseOptions}
          error={errors?.liverDisease}
          register={register}
          required
        />
        {/* depende de liverDisease */}
        {formValues.liverDisease === 'true' && (
          <>
            <Input
              id="liverDiseaseDate"
              label={t('liverDiseaseDate')}
              register={register}
              type="date"
              error={errors?.liverDiseaseDate}
              required
            />
            <Input
              id="liverDiseaseTreatment"
              label={t('liverDiseaseTreatment')}
              register={register}
              error={errors?.liverDiseaseTreatment}
              required
            />
          </>
        )}
      </Container>
      <Container>
        {/* depende de liverDisease */}
        <Select
          label={t('lungDisease')}
          id="lungDisease"
          options={trueFalseOptions}
          error={errors?.lungDisease}
          register={register}
          required
        />
        {/* depende de lungDisease */}
        {formValues.lungDisease === 'true' && (
          <>
            <Input
              id="lungDiseaseDate"
              label={t('lungDiseaseDate')}
              register={register}
              type="date"
              error={errors?.lungDiseaseDate}
              required
            />
            <Input
              id="lungDiseaseTreatment"
              label={t('lungDiseaseTreatment')}
              register={register}
              error={errors?.lungDiseaseTreatment}
            />
          </>
        )}
      </Container>
      <Container>
        {/* depende de lungDisease */}
        <Select
          label={t('kidneyDisease')}
          id="kidneyDisease"
          options={trueFalseOptions}
          error={errors?.kidneyDisease}
          register={register}
          required
        />
        {/* depende de kidneyDisease */}
        {formValues.kidneyDisease === 'true' && (
          <>
            <Input
              label={t('kidneyDiseaseDate')}
              id="kidneyDiseaseDate"
              register={register}
              type="date"
              error={errors?.kidneyDiseaseDate}
              required
            />
            <Input
              label={t('kidneyDiseaseTreatment')}
              id="kidneyDiseaseTreatment"
              register={register}
              error={errors?.kidneyDiseaseTreatment}
              required
            />
          </>
        )}
      </Container>
      <Container>
        {/* depende de kidneyDisease */}
        <Select
          label={t('thyroidProblems')}
          id="thyroidProblems"
          options={trueFalseOptions}
          error={errors?.thyroidProblems}
          register={register}
          required
        />
        {/* depende de thyroidProblems */}
        {formValues.thyroidProblems === 'true' && (
          <>
            <Input
              label={t('thyroidProblemsDate')}
              id="thyroidProblemsDate"
              register={register}
              type="date"
              error={errors?.thyroidProblemsDate}
              required
            />
            <Input
              label={t('thyroidProblemsTreatment')}
              id="thyroidProblemsTreatment"
              register={register}
              error={errors?.thyroidProblemsTreatment}
              required
            />
          </>
        )}
      </Container>
      <Container>
        {/* depende de thyroidProblems */}
        <Select
          label={t('hypertension')}
          id="hypertension"
          options={trueFalseOptions}
          error={errors?.hypertension}
          register={register}
          required
        />
        {/* depende de hypertension */}
        {formValues.hypertension === 'true' && (
          <>
            <Input
              label={t('hypertensionDate')}
              id="hypertensionDate"
              register={register}
              type="date"
              error={errors?.hypertensionDate}
              required
            />
            <Input
              label={t('hypertensionTreatment')}
              id="hypertensionTreatment"
              register={register}
              error={errors?.hypertensionTreatment}
              required
            />
          </>
        )}
      </Container>
      <Container>
        {/* depende de hypertension */}
        <Select
          label={t('otherDisease')}
          id="otherDisease"
          options={trueFalseOptions}
          error={errors?.otherDisease}
          register={register}
          required
        />
      </Container>
      {/* depende de otherDisease */}
      {/* TODO */}

      {formValues?.otherDisease === 'true' && otherDiseasesFields.map((field, index) => (
        <div key={field.id} className="grid-cols-1 grid md:grid-flow-row-dense md:grid-cols-12 grid-rows-1 gap-x-4 hover:grid-flow-row border-slate-200 border-solid border-2 rounded-lg p-5 mb-3">
          <div className="col-span-4">
            <Input
              id={`otherDiseases[${index}].wich`}
              label={t('wichDisease')}
              type="text"
              register={register}
              error={errors?.otherDiseases?.[index]?.wich}
              required
            />
          </div>
          <div className="col-span-3">
            <Input
              id={`otherDiseases[${index}].date`}
              label={t('otherDiseaseDate')}
              type="date"
              register={register}
              error={errors?.otherDiseases?.[index]?.date}
              required
            />
          </div>
          <div className="col-span-4">
            <Input
              id={`otherDiseases[${index}].treatment`}
              label={t('otherDiseaseTreatment')}
              type="text"
              register={register}
              error={errors?.otherDiseases?.[index]?.treatment}
              required
            />
          </div>
          <div className="flex col-span-1 md:justify-end justify-center">
            {index === otherDiseasesFields.length - 1 && (
              <button
                type="button"
                className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                onClick={() => appendOtherDiseases({})}
                aria-label="Add medication"
              >
                <Plus />
              </button>
            )}
            {index !== otherDiseasesFields.length - 1 && (
              <button
                type="button"
                className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                onClick={() => removeOtherDiseases(index)}
                aria-label="Remove medication"
              >
                <Minus />
              </button>
            )}
          </div>
        </div>
      ))}
      {formGeneralValues.gender === 'F' && (
        <>
          {/* TODO */}
          <Title text={t('titleGinecologia')} level={3} />
          <Container>
            <Input
              label={t('lastMenstrualPeriodDay')}
              id="mensualPeriod"
              type="date"
              register={register}
              error={errors?.mensualPeriod}
              required
            />
            <Select
              label={t('sangrado')}
              id="bleeding"
              options={sangradoOptions}
              error={errors?.bleeding}
              register={register}
              required
            />
            <Select
              label={t('pregnant')}
              id="pregnant"
              options={trueFalseOptions}
              error={errors?.pregnant}
              register={register}
              required
            />
            <Select
              label={t('hasBeenPregnant')}
              id="hasBeenPregnant"
              options={trueFalseOptions}
              error={errors?.hasBeenPregnant}
              register={register}
              required
            />
            {/* depende de hasBeenPregnant */}
            {formValues.hasBeenPregnant === 'true' && (
              <>
                <Input
                  label={t('pregnantTimes')}
                  id="pregnantTimes"
                  type="number"
                  register={register}
                  error={errors?.pregnantTimes}
                  required
                />
                <Input
                  label={t('cesareanSections')}
                  id="HowManyCeasarean"
                  type="number"
                  register={register}
                  error={errors?.HowManyCeasarean}
                  required
                />
              </>
            )}
            {/* depende de hasBeenPregnant */}
            <Select
              label={t('contraceptives')}
              id="contraceptives"
              options={trueFalseOptions}
              error={errors?.contraceptives}
              register={register}
              required
            />
          </Container>
          {/* depende de contraceptives */}
          {formValues.contraceptives === 'true' && (
            <>
              {/* TODO */}
              <Title text={t('anticonceptiveTitle')} level={3} />
              {contraceptivesMethodsFields.map((field, index) => (
                <div key={field.id} className="grid-cols-1 grid md:grid-flow-row-dense md:grid-cols-11 grid-rows-1 gap-x-4 hover:grid-flow-row border-slate-200 border-solid border-2 rounded-lg p-5 mb-3">
                  <div className="col-span-5">
                    <Input
                      id={`contraceptivesMethods[${index}].method`}
                      label={t('currentlyUsed')}
                      type="text"
                      register={register}
                      error={errors?.contraceptivesMethods?.[index]?.method}
                      required
                    />
                  </div>
                  <div className="col-span-5">
                    <Input
                      id={`contraceptivesMethods[${index}].timeOfUse`}
                      label={t('timeOfUse')}
                      type="text"
                      register={register}
                      error={errors?.contraceptivesMethods?.[index]?.timeOfUse}
                      required
                    />
                  </div>
                  <div className="flex col-span-1 md:justify-end justify-center">
                    {index === otherDiseasesFields.length - 1 && (
                    <button
                      type="button"
                      className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                      onClick={() => appendContraceptivesMethods({})}
                      aria-label="Add medication"
                    >
                      <Plus />
                    </button>
                    )}
                    {index !== otherDiseasesFields.length - 1 && (
                    <button
                      type="button"
                      className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                      onClick={() => removeContraceptivesMethods(index)}
                      aria-label="Remove medication"
                    >
                      <Minus />
                    </button>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
          <Container>
            <Select
              label={t('hormones')}
              id="hormones"
              options={trueFalseOptions}
              error={errors?.hormones}
              register={register}
              required
            />
          </Container>
          {formValues.hormones === 'true' && (
            <>
              {/* TODO */}
              <Title text={t('titleHormones')} level={3} />
              {hormonesFields.map((field, index) => (
                <div key={field.id} className="grid-cols-1 grid md:grid-flow-row-dense md:grid-cols-11 grid-rows-1 gap-x-4 hover:grid-flow-row border-slate-200 border-solid border-2 rounded-lg p-5 mb-3">
                  <div className="col-span-5">
                    <Input
                      id={`wichHormones[${index}].type`}
                      label={t('typeOfHormones')}
                      type="text"
                      register={register}
                      error={errors?.wichHormones?.[index]?.type}
                      required
                    />
                  </div>
                  <div className="col-span-5">
                    <Input
                      id={`wichHormones[${index}].timeOfUse`}
                      label={t('timeOfUse')}
                      type="text"
                      register={register}
                      error={errors?.contraceptivesMethods?.[index]?.timeOfUse}
                      required
                    />
                  </div>
                  <div className="flex col-span-1 md:justify-end justify-center">
                    {index === otherDiseasesFields.length - 1 && (
                    <button
                      type="button"
                      className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                      onClick={() => appendHormones({})}
                      aria-label="Add medication"
                    >
                      <Plus />
                    </button>
                    )}
                    {index !== otherDiseasesFields.length - 1 && (
                    <button
                      type="button"
                      className="flex items-center rounded-full bg-slate-500 text-white font-bold h-14 w-14 self-center justify-center hover:bg-slate-600 transition-all duration-300 ease-in-out"
                      onClick={() => removeHormones(index)}
                      aria-label="Remove medication"
                    >
                      <Minus />
                    </button>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </>
      )}
      {children}
    </form>
  );
};

export default HistorialMedico;
