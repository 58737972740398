import Container from 'components/Container';
import Input from 'components/Input';
import Select from 'components/Select';
import Title from 'components/Title';
import { oncologyImageStudiesOptions, trueFalseOptions } from 'utils/enums';
import { useTranslation } from 'react-i18next';

const Oncology = ({
  register,
  errors,
  formValues,
}) => {
  const { t } = useTranslation('global');

  return (
    <>
      <Container>
        <Select
          label={t('toDiagnosis')}
          id="toDiagnosis"
          options={trueFalseOptions}
          register={register}
          error={errors.toDiagnosis}
          required
        />
        {formValues.toDiagnosis === 'true' && (
          <Input
            label={t('oncologyReason')}
            id="oncologyReason"
            type="text"
            register={register}
            error={errors.oncologyReason}
            required
            col={2}
          />
        )}

      </Container>
      <Container>
        <Select
          label={t('oncologyTreatment')}
          id="oncologyTreatment"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyTreatment}
          required
        />
        {formValues.oncologyTreatment === 'true' && (
          <Input
            label={t('oncologyDiagnosis')}
            id="oncologyDiagnosis"
            register={register}
            error={errors.oncologyDiagnosis}
            type="text"
            required
            col={2}
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologySecondOpinion')}
          id="oncologySecondOpinion"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologySecondOpinion}
          required
        />
        {formValues.oncologySecondOpinion === 'true' && (
          <Input
            label={t('oncologySecondOpinionReason')}
            id="oncologySecondOpinionReason"
            register={register}
            error={errors.oncologySecondOpinionReason}
            type="text"
            required
            col={2}
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyEvaluated')}
          id="oncologyEvaluated"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyEvaluated}
          required
        />
        {formValues.oncologyEvaluated === 'true' && (
          <Input
            label={t('oncologyEvaluatedReason')}
            id="oncologyEvaluatedReason"
            register={register}
            error={errors.oncologyEvaluatedReason}
            type="text"
            required
            col={2}
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyComplementaryServices')}
          id="oncologyComplementaryServices"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyComplementaryServices}
          required
        />
        {formValues.oncologyComplementaryServices === 'true' && (
          <Input
            label={t('oncologyComplementaryServicesInterest')}
            id="oncologyComplementaryServicesInterest"
            register={register}
            error={errors.oncologyComplementaryServicesInterest}
            type="text"
            required
            col={2}
          />
        )}
      </Container>
      <Title text={t('oncologyReasonTitle')} level={3} />
      <Container>
        <Select
          label={t('oncologyWaitTime')}
          id="oncologyWaitTime"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyWaitTime}
          required
        />
        <Select
          label={t('oncologyCommunication')}
          id="oncologyCommunication"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyCommunication}
          required
        />
        <Select
          label={t('oncologyFinancialReasons')}
          id="oncologyFinancialReasons"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyFinancialReasons}
          required
        />
        <Select
          label={t('oncologyOtherReasons')}
          id="oncologyOtherReasons"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyOtherReasons}
          required
        />
        {formValues.oncologyOtherReasons === 'true' && (
          <Input
            label={t('oncologyOtherReasonsName')}
            id="oncologyOtherReasonsName"
            register={register}
            error={errors.oncologyOtherReasonsName}
            type="text"
            required
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyPalliativeTreatment')}
          id="oncologyPalliativeTreatment"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyPalliativeTreatment}
          required
        />
        {formValues.oncologyPalliativeTreatment === 'true' && (
          <Input
            label={t('oncologyPalliativeTreatmentReason')}
            id="oncologyPalliativeTreatmentReason"
            register={register}
            error={errors.oncologyPalliativeTreatmentReason}
            type="text"
            required
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyDiagnosticStudies')}
          id="oncologyDiagnosticStudies"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyDiagnosticStudies}
          required
        />
        {formValues.oncologyDiagnosticStudies === 'true' && (
          <Input
            label={t('oncologyDiagnosticStudiesName')}
            id="oncologyDiagnosticStudiesName"
            register={register}
            error={errors.oncologyDiagnosticStudiesName}
            type="text"
            required
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyAJCCStage')}
          id="oncologyAJCCStage"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyAJCCStage}
          required
        />
        {formValues.oncologyAJCCStage === 'true' && (
          <>
            <Input
              label={t('oncologyAJCCStageType')}
              id="oncologyAJCCStageType"
              register={register}
              error={errors.oncologyAJCCStageType}
              type="text"
              required
            />
            <Input
              label={t('oncologyAJCCStageArea')}
              id="oncologyAJCCStageArea"
              register={register}
              error={errors.oncologyAJCCStageArea}
              type="text"
              required
            />
            <Input
              label={t('oncologyAJCCStageDate')}
              id="oncologyAJCCStageDate"
              register={register}
              error={errors.oncologyAJCCStageDate}
              type="date"
              required
            />
          </>
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyLabStudies')}
          id="oncologyLabStudies"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyLabStudies}
          required
        />
        {formValues.oncologyLabStudies === 'true' && (
          <Input
            label={t('oncologyLabStudiesFile')}
            id="oncologyLabStudiesFile"
            register={register}
            error={errors.oncologyLabStudiesFile}
            type="file"
            required
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyTumorMarkers')}
          id="oncologyTumorMarkers"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyTumorMarkers}
          required
        />
        {formValues.oncologyTumorMarkers === 'true' && (
          <Input
            label={t('oncologyTumorMarkersFile')}
            id="oncologyTumorMarkersFile"
            register={register}
            error={errors.oncologyTumorMarkersFile}
            type="file"
            required
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyGeneticTest')}
          id="oncologyGeneticTest"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyGeneticTest}
          required
        />
        {formValues.oncologyGeneticTest === 'true' && (
          <Input
            label={t('oncologyGeneticTestFile')}
            id="oncologyGeneticTestFile"
            register={register}
            error={errors.oncologyGeneticTestFile}
            type="file"
            required
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyXRayStudies')}
          id="oncologyXRayStudies"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyXRayStudies}
          required
        />
        {formValues.oncologyXRayStudies === 'true' && (
          <Input
            label={t('oncologyXRayStudiesFile')}
            id="oncologyXRayStudiesFile"
            register={register}
            error={errors.oncologyXRayStudiesFile}
            type="file"
            required
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyBiopsy')}
          id="oncologyBiopsy"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyBiopsy}
          required
        />
        {formValues.oncologyBiopsy === 'true' && (
          <>
            <Input
              label={t('oncologyBiopsyFile')}
              id="oncologyBiopsyFile"
              register={register}
              error={errors.oncologyBiopsyFile}
              type="file"
              required
            />
            <Input
              label={t('oncologyBiopsyType')}
              id="oncologyBiopsyType"
              register={register}
              error={errors.oncologyBiopsyType}
              type="text"
              required
            />
          </>
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyImmunohistochemistry')}
          id="oncologyImmunohistochemistry"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyImmunohistochemistry}
          required
        />
        {formValues.oncologyImmunohistochemistry === 'true' && (
          <>
            <Input
              label={t('oncologyImmunochemistryLabStudiesFile')}
              id="oncologyImmunochemistryLabStudiesFile"
              register={register}
              error={errors.oncologyLabStudiesFile}
              type="file"
              required
            />
            <Input
              label={t('oncologyImmunohistochemistryType')}
              id="oncologyImmunohistochemistryType"
              register={register}
              error={errors.oncologyImmunohistochemistryType}
              type="text"
              required
            />
          </>
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyImageStudies')}
          id="oncologyImageStudies"
          options={oncologyImageStudiesOptions}
          register={register}
          error={errors.oncologyImageStudies}
          required
        />
        {formValues.oncologyImageStudies === '5' && (
          <Input
            label={t('oncologyImageStudiesName')}
            id="oncologyImageStudiesName"
            register={register}
            error={errors.oncologyImageStudiesName}
            type="text"
            required
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyTreatmentReceived')}
          id="oncologyTreatmentReceived"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyTreatmentReceived}
          required
        />
        {formValues.oncologyTreatmentReceived === 'true' && (
          <>
            <Input
              label={t('oncologyTreatmentReceivedName')}
              id="oncologyTreatmentReceivedName"
              register={register}
              error={errors.oncologyTreatmentReceivedName}
              type="text"
              required
            />
            <Input
              label={t('oncologyTreatmentReceivedDate')}
              id="oncologyTreatmentReceivedDate"
              register={register}
              error={errors.oncologyTreatmentReceivedDate}
              type="date"
              required
            />
          </>
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyCancerSurgery')}
          id="oncologyCancerSurgery"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyCancerSurgery}
          required
        />
        {formValues.oncologyCancerSurgery === 'true' && (
          <>
            <Input
              label={t('oncologyCancerSurgeryName')}
              id="oncologyCancerSurgeryName"
              register={register}
              error={errors.oncologyCancerSurgeryName}
              type="text"
              required
            />
            <Input
              label={t('oncologyCancerSurgeryDate')}
              id="oncologyCancerSurgeryDate"
              register={register}
              error={errors.oncologyCancerSurgeryDate}
              type="date"
              required
            />
          </>
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyChemotherapy')}
          id="oncologyChemotherapy"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyChemotherapy}
          required
        />
        {formValues.oncologyChemotherapy === 'true' && (
          <>
            <Input
              label={t('oncologyChemotherapyTreatment')}
              id="oncologyChemotherapyTreatment"
              register={register}
              error={errors.oncologyChemotherapyTreatment}
              type="text"
              required
            />
            <Input
              label={t('oncologyChemotherapyReason')}
              id="oncologyChemotherapyReason"
              register={register}
              error={errors.oncologyChemotherapyReason}
              type="text"
              required
            />
            <Input
              label={t('oncologyChemotherapyDate')}
              id="oncologyChemotherapyDate"
              register={register}
              error={errors.oncologyChemotherapyDate}
              type="date"
              required
            />
          </>
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyRadiationTreatment')}
          id="oncologyRadiationTreatment"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyRadiationTreatment}
          required
        />
        {formValues.oncologyRadiationTreatment === 'true' && (
          <>
            <Input
              label={t('oncologyRadiationTreatmentName')}
              id="oncologyRadiationTreatmentName"
              register={register}
              error={errors.oncologyRadiationTreatmentName}
              type="text"
              required
            />
            <Input
              label={t('oncologyRadiationTreatmentReason')}
              id="oncologyRadiationTreatmentReason"
              register={register}
              error={errors.oncologyRadiationTreatmentReason}
              type="text"
              required
            />
            <Input
              label={t('oncologyRadiationTreatmentDate')}
              id="oncologyRadiationTreatmentDate"
              register={register}
              error={errors.oncologyRadiationTreatmentDate}
              type="date"
              required
            />
          </>
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyHormonalTherapy')}
          id="oncologyHormonalTherapy"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyHormonalTherapy}
          required
        />
        {formValues.oncologyHormonalTherapy === 'true' && (
          <>
            <Input
              label={t('oncologyHormonalTherapyReason')}
              id="oncologyHormonalTherapyReason"
              register={register}
              error={errors.oncologyHormonalTherapyReason}
              type="text"
              required
            />
            <Input
              label={t('oncologyHormonalTherapyTreatment')}
              id="oncologyHormonalTherapyTreatment"
              register={register}
              error={errors.oncologyHormonalTherapyTreatment}
              type="text"
              required
            />
            <Input
              label={t('oncologyHormonalTherapyTime')}
              id="oncologyHormonalTherapyTime"
              register={register}
              error={errors.oncologyHormonalTherapyTime}
              type="text"
              required
            />
          </>
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyImmunotherapy')}
          id="oncologyImmunotherapy"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyImmunotherapy}
          required
        />
        {formValues.oncologyImmunotherapy === 'true' && (
          <>
            <Input
              label={t('oncologyImmunotherapyReason')}
              id="oncologyImmunotherapyReason"
              register={register}
              error={errors.oncologyImmunotherapyReason}
              type="text"
              required
            />
            <Input
              label={t('oncologyImmunotherapyDate')}
              id="oncologyImmunotherapyDate"
              register={register}
              error={errors.oncologyImmunotherapyDate}
              type="date"
              required
            />
          </>
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyPalliativeCare')}
          id="oncologyPalliativeCare"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyPalliativeCare}
          required
        />
        {formValues.oncologyPalliativeCare === 'true' && (
          <>
            <Input
              label={t('oncologyPalliativeCareReason')}
              id="oncologyPalliativeCareReason"
              register={register}
              error={errors.oncologyPalliativeCareReason}
              type="text"
              required
            />
            <Input
              label={t('oncologyPalliativeCareDate')}
              id="oncologyPalliativeCareDate"
              register={register}
              error={errors.oncologyPalliativeCareDate}
              type="date"
              required
            />
          </>
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyVascularAccess')}
          id="oncologyVascularAccess"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyVascularAccess}
          required
        />
        {formValues.oncologyVascularAccess === 'true' && (
          <>
            <Input
              label={t('oncologyVascularAccessName')}
              id="oncologyVascularAccessName"
              register={register}
              error={errors.oncologyVascularAccessName}
              type="text"
              required
            />
            <Input
              label={t('oncologyVascularAccessDate')}
              id="oncologyVascularAccessDate"
              register={register}
              error={errors.oncologyVascularAccessDate}
              type="date"
              required
            />
          </>
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyTreatmentPreference')}
          id="oncologyTreatmentPreference"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyTreatmentPreference}
          required
        />
        {formValues.oncologyTreatmentPreference === 'true' && (
          <Input
            label={t('oncologyTreatmentPreferenceName')}
            id="oncologyTreatmentPreferenceName"
            register={register}
            error={errors.oncologyTreatmentPreferenceName}
            type="text"
            required
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('oncologyPreviousExperiences')}
          id="oncologyPreviousExperiences"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyPreviousExperiences}
          required
        />
        {formValues.oncologyPreviousExperiences === 'true' && (
          <>
            <Input
              label={t('oncologyPreviousExperiencesWith')}
              id="oncologyPreviousExperiencesWith"
              register={register}
              error={errors.oncologyPreviousExperiencesWith}
              type="text"
              required
            />
            <Input
              label={t('oncologyPreviousExperiencesDate')}
              id="oncologyPreviousExperiencesDate"
              register={register}
              error={errors.oncologyPreviousExperiencesDate}
              type="date"
              required
            />
          </>
        )}
      </Container>
      <Input
        label={t('oncologyFinalResult')}
        id="oncologyFinalResult"
        type="text"
        register={register}
        error={errors.oncologyFinalResult}
        required
      />
      <Title text={t('oncologyTitle2')} level={3} />
      <Container>
        <Select
          label={t('oncologyDiabetes')}
          id="oncologyDiabetes"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyDiabetes}
          required
        />
        <Select
          label={t('oncologyHypertension')}
          id="oncologyHypertension"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyHypertension}
          required
        />
        <Select
          label={t('oncologyHepatitis')}
          id="oncologyHepatitis"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyHepatitis}
          required
        />
        <Select
          label={t('oncologyAsthma')}
          id="oncologyAsthma"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyAsthma}
          required
        />
        <Select
          label={t('oncologyEpilepsy')}
          id="oncologyEpilepsy"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyEpilepsy}
          required
        />
        <Select
          label={t('oncologyHIV')}
          id="oncologyHIV"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyHIV}
          required
        />
        <Select
          label={t('oncologyHypothyroidism')}
          id="oncologyHypothyroidism"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyHypothyroidism}
          required
        />
        <Select
          label={t('oncologyOther')}
          id="oncologyOther"
          options={trueFalseOptions}
          register={register}
          error={errors.oncologyOther}
          required
        />
      </Container>
    </>
  );
};

export default Oncology;
