import { z } from 'zod';
import {
  goodRegularBadOptions,
  oncologyImageStudiesOptions,
  servicesEnum,
  sesibilityOptions,
} from 'utils/enums';
import { selectServices } from 'utils/store';
import { getCurrentLanguage } from 'components/ToggleLanguege';

const MAX_FILE_SIZE = 5000000;

// const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];

// Esquema de validación de la cita
export const applicationSchema = () => {
  const lenguage = getCurrentLanguage();
  const isSpanish = lenguage === 'es';
  const _services = selectServices();
  const requiredMessage = isSpanish ? 'Este campo es requerido' : 'This field is required';
  const defaultOption = isSpanish ? 'Seleccione una opción' : 'Select an option';
  const validDateMessage = isSpanish
    ? 'Ingrese una fecha de nacimiento válida' : 'Enter a valid birthdate';
  const phoneValidationMessage = isSpanish
    ? 'Ingrese un número de teléfono válido' : 'Enter a valid phone number';
  const emailValidationMessage = isSpanish
    ? 'Ingrese un correo electrónico válido' : 'Enter a valid email';
  const phone2ValidationMessage = isSpanish
    ? 'El teléfono 2 no puede ser igual al teléfono 1' : 'Phone 2 cannot be the same as phone 1';
  const maxFileSizeMessage = isSpanish
    ? 'El tamaño máximo de la imagen es de 5MB' : 'Max image size is 5MB';
  // const supportedFileTypesMessage = isSpanish
  //   ? `Solo ${ACCEPTED_IMAGE_TYPES.join(', ')} son soportados` : `Only ${ACCEPTED_IMAGE_TYPES.join(', ')} are supported`;

  const procedures = localStorage.getItem('PROCEDURES') ? JSON.parse(localStorage.getItem('PROCEDURES')) : [];
  const proceduresEnum = procedures.map((procedure) => procedure.value.toString());

  // Función para manejar errores de enum
  const handleEnumError = (issue) => {
    if (issue.code === z.ZodIssueCode.invalid_enum_value) {
      return { message: requiredMessage };
    }
    if (issue.code === z.ZodIssueCode.invalid_type) {
      return { message: requiredMessage };
    }
    return { message: issue.message ?? '' };
  };

  // Función para validar la longitud del teléfono
  const validatePhoneLength = (value) => value.length === 10;

  // Esquemas de validación de campos de texto requeridos
  const required = z.string().min(1, { message: requiredMessage });

  const trueFalseSchema = z.custom()
    .transform((value) => {
      if (value === defaultOption) return undefined;
      return value === 'true';
    }).refine((value) => value !== undefined, { message: requiredMessage });

  // Esquema de validación de campos de teléfono requeridos
  const requiredPhone = z.string().min(1, { message: requiredMessage }).refine(validatePhoneLength, {
    message: phoneValidationMessage,
  });

  // Esquema de validación de campos de género requeridos
  const genderSchema = z.enum(
    ['M', 'F'],
    {
      errorMap: handleEnumError,
    },
  );

  // Esquema de validación de campos de fecha de nacimiento requeridos
  const dateSchema = z
    .string()
    .min(1, { message: requiredMessage })
    .refine(
      (value) => {
        const date = new Date(value);
        return !Number.isNaN(date.getTime());
      },
      { message: validDateMessage },
    );

  // Esquema de validación de campos de imagen requeridos
  const imageSchema = z
    .custom()
    .transform((file) => file?.[0])
    .refine((file) => file !== undefined, { message: requiredMessage }).optional();

  return z
    .object({
      name: required,
      lastName: required,
      email: z.string().email({ message: emailValidationMessage }),
      phone: requiredPhone,
      phone2: z.string().min(0).superRefine((value, ctx) => {
        const { phone } = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES'));
        if (value && phone && (phone === value)) {
          ctx.addIssue({
            message: phone2ValidationMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
      }).optional(),
      gender: genderSchema,
      birthdate: dateSchema,
      beforeTreatment: trueFalseSchema,
      country: required,
      state: required,
      contactName: required,
      contactPhone: requiredPhone,
      contactRelation: required,
      zipCode: z.optional(z.string().min(0)),
      service: z.enum(
        _services.map((option) => option.value?.toString()),
        { errorMap: handleEnumError },
      ),
      procedure: z.enum(proceduresEnum, { errorMap: handleEnumError }).optional(),
      frontPhoto: imageSchema.superRefine((value, ctx) => {
        if (value.size > MAX_FILE_SIZE) {
          ctx.addIssue({
            message: maxFileSizeMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
        const { teethShowing, service } = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES'));
        if (!teethShowing && !value && service === servicesEnum.PLASTIC_SURGERY) {
          ctx.addIssue({
            message: requiredMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
      }).optional(),
      backPhoto: imageSchema.superRefine((value, ctx) => {
        if (value.size > MAX_FILE_SIZE) {
          ctx.addIssue({
            message: maxFileSizeMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
        const { teethShowing, service } = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES'));
        if (!teethShowing && !value && service === servicesEnum.PLASTIC_SURGERY) {
          ctx.addIssue({
            message: requiredMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
      }).optional(),
      leftPhoto: imageSchema.superRefine((value, ctx) => {
        if (value.size > MAX_FILE_SIZE) {
          ctx.addIssue({
            message: maxFileSizeMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
        const { teethShowing, service } = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES'));
        if (!teethShowing && !value && service === servicesEnum.PLASTIC_SURGERY) {
          ctx.addIssue({
            message: requiredMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
      }).optional(),
      rigthPhoto: imageSchema.superRefine((value, ctx) => {
        if (value.size > MAX_FILE_SIZE) {
          ctx.addIssue({
            message: maxFileSizeMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
        const { teethShowing, service } = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES'));
        if (!teethShowing && !value && service === servicesEnum.PLASTIC_SURGERY) {
          ctx.addIssue({
            message: requiredMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
      }).optional(),
      // Dental Fields
      teethShowing: imageSchema.superRefine((value, ctx) => {
        if (value.size > MAX_FILE_SIZE) {
          ctx.addIssue({
            message: maxFileSizeMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
        const { teethShowing, service } = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES'));
        if (!teethShowing && !value && service === servicesEnum.DENTAL) {
          ctx.addIssue({
            message: requiredMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
      }).optional(),
      mouthOpenTeethUp: imageSchema.superRefine((value, ctx) => {
        if (value.size > MAX_FILE_SIZE) {
          ctx.addIssue({
            message: maxFileSizeMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
        const { teethShowing, service } = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES'));
        if (!teethShowing && !value && service === servicesEnum.DENTAL) {
          ctx.addIssue({
            message: requiredMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
      }).optional(),
      mouthOpenTeethDown: imageSchema.superRefine((value, ctx) => {
        if (value.size > MAX_FILE_SIZE) {
          ctx.addIssue({
            message: maxFileSizeMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
        const { teethShowing, service } = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES'));
        if (!teethShowing && !value && service === servicesEnum.DENTAL) {
          ctx.addIssue({
            message: requiredMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
      }).optional(),
      mouthOpenTeethShowing: imageSchema.superRefine((value, ctx) => {
        if (value.size > MAX_FILE_SIZE) {
          ctx.addIssue({
            message: maxFileSizeMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
        const { teethShowing, service } = JSON.parse(localStorage.getItem('FORM_GENERAL_VALUES'));
        if (!teethShowing && !value && service === servicesEnum.DENTAL) {
          ctx.addIssue({
            message: requiredMessage,
            code: z.ZodIssueCode.invalid_type,
          });
        }
      }).optional(),
      firstVisitToDentist: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      timeSinceLastVisit: z.string().min(1, { message: requiredMessage }).optional(),
      radiography: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      reactionToDentalAnesthetic: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      reactionToDentalAnestheticWhich: required.optional(),
      brushingTimesPerDay: required.optional(),
      patientDentalHealthDescription: z.optional(
        z.enum(
          goodRegularBadOptions.map((option) => option.value),
          { errorMap: handleEnumError },
        ).optional(),
      ),
      grindsTeeth: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      lockedJaw: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      dentalSensitivity: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      dentalSensitivityTo: z.enum(
        sesibilityOptions.filter((_, i) => i !== 0).map((i) => i.value),
        { errorMap: handleEnumError },
      ).optional(),
      swallowingProblems: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      mouthBreather: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      smokes: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      smokesHowMuch: required.optional(),
      temporomandibularJointNoises: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      habit: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      bleedingGums: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      temporomandibularJointPain: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      drugsConsumption: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      drugsConsumed: required.optional(),
      drugsConsumedHowMuch: required.optional(),
      oralHygieneProblems: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      faceOrMouthBlow: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      previousOrthodonticTreatment: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      frequentNauseaOrVomiting: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      alcoholConsumption: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      alcoholConsumedHowMuch: required.optional(),
      other: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      otherWhich: required.optional(),
      reasonForVisit: required.optional(),
      satisfiedWithSmile: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      satisfiedWithSmileWhy: required.optional(),
      familyOrthodonticTreatment: z.enum(['true', 'false'], { errorMap: handleEnumError }).optional(),
      // Oncology Fields
      toDiagnosis: trueFalseSchema.optional(),
      oncologyReason: z.string().min(1, requiredMessage).optional(),
      oncologyTreatment: trueFalseSchema.optional(),
      oncologyDiagnosis: z.string().min(1, requiredMessage).optional(),
      oncologySecondOpinion: trueFalseSchema.optional(),
      oncologySecondOpinionReason: z.string().min(1, requiredMessage).optional(),
      oncologyEvaluated: trueFalseSchema.optional(),
      oncologyEvaluatedReason: z.string().min(1, requiredMessage).optional(),
      oncologyComplementaryServices: trueFalseSchema.optional(),
      oncologyComplementaryServicesInterest: z.string().min(1, requiredMessage).optional(),
      oncologyWaitTime: trueFalseSchema.optional(),
      oncologyCommunication: trueFalseSchema.optional(),
      oncologyFinancialReasons: trueFalseSchema.optional(),
      oncologyOtherReasons: trueFalseSchema.optional(),
      oncologyPalliativeTreatment: trueFalseSchema.optional(),
      oncologyPalliativeTreatmentReason: z.string().min(1, requiredMessage).optional(),
      oncologyDiagnosticStudies: trueFalseSchema.optional(),
      oncologyDiagnosticStudiesName: z.string().min(1, requiredMessage).optional(),
      oncologyAJCCStage: trueFalseSchema.optional(),
      oncologyAJCCStageType: z.string().min(1, requiredMessage).optional(),
      oncologyAJCCStageArea: z.string().min(1, requiredMessage).optional(),
      oncologyAJCCStageDate: dateSchema.optional(),
      oncologyLabStudies: trueFalseSchema.optional(),
      // oncologyLabStudiesFile: FileSchema TODO
      oncologyTumorMarkers: trueFalseSchema.optional(),
      // oncologyTumorMarkersFile: FileSchema TODO
      oncologyGeneticTest: trueFalseSchema.optional(),
      // oncologyGeneticTestFile: FileSchema TODO
      oncologyXRayStudies: trueFalseSchema.optional(),
      // oncologyXRayStudiesFile: FileSchema TODO
      oncologyBiopsy: trueFalseSchema.optional(),
      oncologyBiopsyType: z.string().min(1, requiredMessage).optional(),
      // oncologyBiopsyFile: FileSchema TODO
      oncologyImmunohistochemistry: trueFalseSchema.optional(),
      oncologyImmunohistochemistryType: z.string().min(1, requiredMessage).optional(),
      // oncologyLabStudiesFile: FileSchema TODO
      oncologyImageStudies: z.enum(oncologyImageStudiesOptions.map((i) => i.value), {
        errorMap: handleEnumError,
      }).optional(),
      oncologyImageStudiesName: z.string().min(1, requiredMessage).optional(),
      oncologyTreatmentReceived: trueFalseSchema.optional(),
      oncologyTreatmentReceivedName: z.string().min(1, requiredMessage).optional(),
      oncologyTreatmentReceivedDate: dateSchema.optional(),
      oncologyCancerSurgery: trueFalseSchema.optional(),
      oncologyCancerSurgeryName: z.string().min(1, requiredMessage).optional(),
      oncologyCancerSurgeryDate: dateSchema.optional(),
      oncologyChemotherapy: trueFalseSchema.optional(),
      oncologyChemotherapyTreatment: z.string().min(1, requiredMessage).optional(),
      oncologyChemotherapyReason: z.string().min(1, requiredMessage).optional(),
      oncologyChemotherapyDate: dateSchema.optional(),
      oncologyRadiationTreatment: trueFalseSchema.optional(),
      oncologyRadiationTreatmentName: z.string().min(1, requiredMessage).optional(),
      oncologyRadiationTreatmentReason: z.string().min(1, requiredMessage).optional(),
      oncologyRadiationTreatmentDate: dateSchema.optional(),
      oncologyHormonalTherapy: trueFalseSchema.optional(),
      oncologyHormonalTherapyReason: z.string().min(1, requiredMessage).optional(),
      oncologyHormonalTherapyTreatment: z.string().min(1, requiredMessage).optional(),
      oncologyHormonalTherapyTime: z.string().min(1, requiredMessage).optional(),
      oncologyImmunotherapy: trueFalseSchema.optional(),
      oncologyImmunotherapyReason: z.string().min(1, requiredMessage).optional(),
      oncologyImmunotherapyDate: dateSchema.optional(),
      oncologyPalliativeCare: trueFalseSchema.optional(),
      oncologyPalliativeCareReason: z.string().min(1, requiredMessage).optional(),
      oncologyPalliativeCareDate: dateSchema.optional(),
      oncologyVascularAccess: trueFalseSchema.optional(),
      oncologyVascularAccessName: z.string().min(1, requiredMessage).optional(),
      oncologyVascularAccessDate: dateSchema.optional(),
      oncologyTreatmentPreference: trueFalseSchema.optional(),
      oncologyTreatmentPreferenceName: z.string().min(1, requiredMessage).optional(),
      oncologyPreviousExperiences: trueFalseSchema.optional(),
      oncologyPreviousExperiencesWith: z.string().min(1, requiredMessage).optional(),
      oncologyPreviousExperiencesDate: dateSchema.optional(),
      oncologyFinalResult: z.string().min(1, requiredMessage).optional(),
      oncologyDiabetes: trueFalseSchema.optional(),
      oncologyHypertension: trueFalseSchema.optional(),
      oncologyHepatitis: trueFalseSchema.optional(),
      oncologyAsthma: trueFalseSchema.optional(),
      oncologyEpilepsy: trueFalseSchema.optional(),
      oncologyHIV: trueFalseSchema.optional(),
      oncologyHypothyroidism: trueFalseSchema.optional(),
      oncologyOther: trueFalseSchema.optional(),
    });
};
