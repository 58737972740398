import Container from 'components/Container';
import Dragger from 'components/Dragger';
import Input from 'components/Input';
import Select from 'components/Select';
import Title from 'components/Title';
import { useTranslation } from 'react-i18next';
import { goodRegularBadOptions, sesibilityOptions, trueFalseOptions } from 'utils/enums';

const Dental = ({
  register,
  errors,
  formValues,
  resetField,
  clearErrors,
}) => {
  const { t } = useTranslation('global');

  return (
    <>
      <div className="grid gap-x-4 grid-cols-1 md:grid-cols-2">
        <Dragger
          register={register}
          id="teethShowing"
          label={t('teethShowing')}
          required
          error={errors.teethShowing}
          resetField={resetField}
          clearErrors={clearErrors}
        />
        <Dragger
          label={t('mouthOpenTeethUp')}
          register={register}
          id="mouthOpenTeethUp"
          required
          error={errors.mouthOpenTeethUp}
          resetField={resetField}
          clearErrors={clearErrors}
        />
        <Dragger
          label={t('mouthOpenTeethDown')}
          register={register}
          id="mouthOpenTeethDown"
          required
          error={errors.mouthOpenTeethDown}
          resetField={resetField}
          clearErrors={clearErrors}
        />
        <Dragger
          label={t('mouthOpenTeethShowing')}
          register={register}
          id="mouthOpenTeethShowing"
          required
          error={errors.mouthOpenTeethShowing}
          resetField={resetField}
          clearErrors={clearErrors}
        />
      </div>
      <Container>
        <Select
          label={t('firstVisitToDentist')}
          id="firstVisitToDentist"
          options={trueFalseOptions}
          error={errors.firstVisitToDentist}
          register={register}
          required
        />
        {formValues?.firstVisitToDentist === 'false' && (
          <Input
            label={t('timeSinceLastVisit')}
            type="text"
            id="timeSinceLastVisit"
            error={errors.timeSinceLastVisit}
            register={register}
            required
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('radiography')}
          id="radiography"
          options={trueFalseOptions}
          error={errors.radiography}
          register={register}
          required
        />
        <Select
          label={t('reactionToDentalAnesthetic')}
          id="reactionToDentalAnesthetic"
          options={trueFalseOptions}
          error={errors.reactionToDentalAnesthetic}
          register={register}
          required
        />
        {formValues?.reactionToDentalAnesthetic === 'true' && (
          <Input
            label={t('reactionToDentalAnestheticWhich')}
            type="text"
            id="reactionToDentalAnestheticWhich"
            error={errors.reactionToDentalAnestheticWhich}
            register={register}
            required
          />
        )}
      </Container>
      <Container>
        <Input
          label={t('brushingTimesPerDay')}
          type="text"
          id="brushingTimesPerDay"
          error={errors.brushingTimesPerDay}
          register={register}
          required
        />
        <Select
          label={t('patientDentalHealthDescription')}
          id="patientDentalHealthDescription"
          options={goodRegularBadOptions}
          error={errors.patientDentalHealthDescription}
          register={register}
          required
        />
      </Container>
      <Title text={t('dentalTitle')} level={3} />
      <Container>
        <Select
          label={t('grindsTeeth')}
          id="grindsTeeth"
          options={trueFalseOptions}
          error={errors.grindsTeeth}
          register={register}
          required
        />
        <Select
          label={t('lockedJaw')}
          id="lockedJaw"
          options={trueFalseOptions}
          error={errors.lockedJaw}
          register={register}
          required
        />
      </Container>
      <Container>
        <Select
          label={t('dentalSensitivity')}
          id="dentalSensitivity"
          options={trueFalseOptions}
          error={errors.dentalSensitivity}
          register={register}
          required
        />
        {formValues?.dentalSensitivity === 'true' && (
        <Select
          label={t('dentalSensitivityTo')}
          id="dentalSensitivityTo"
          options={sesibilityOptions}
          error={errors.dentalSensitivityTo}
          register={register}
          required
        />
        )}
      </Container>
      <Container>
        <Select
          label={t('swallowingProblems')}
          id="swallowingProblems"
          options={trueFalseOptions}
          error={errors.swallowingProblems}
          register={register}
          required
        />
        <Select
          label={t('mouthBreather')}
          id="mouthBreather"
          options={trueFalseOptions}
          error={errors.mouthBreather}
          register={register}
          required
        />
      </Container>
      <Container>
        <Select
          label={t('smokes')}
          id="smokes"
          options={trueFalseOptions}
          error={errors.smokes}
          register={register}
          required
        />
        {formValues?.smokes === 'true' && (
          <Input
            label={t('smokesHowMuch')}
            type="text"
            id="smokesHowMuch"
            error={errors.smokesHowMuch}
            register={register}
            required
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('temporomandibularJointNoises')}
          id="temporomandibularJointNoises"
          options={trueFalseOptions}
          error={errors.temporomandibularJointNoises}
          register={register}
          required
        />
        <Select
          label={t('habit')}
          id="habit"
          options={trueFalseOptions}
          error={errors.habit}
          register={register}
          required
        />
        <Select
          label={t('bleedingGums')}
          id="bleedingGums"
          options={trueFalseOptions}
          error={errors.bleedingGums}
          register={register}
          required
        />
        <Select
          label={t('temporomandibularJointPain')}
          id="temporomandibularJointPain"
          options={trueFalseOptions}
          error={errors.temporomandibularJointPain}
          register={register}
          required
        />
      </Container>
      <Container>
        <Select
          label={t('drugsConsumption')}
          id="drugsConsumption"
          options={trueFalseOptions}
          error={errors.drugsConsumption}
          register={register}
          required
        />
        {formValues?.drugsConsumption === 'true' && (
          <>
            <Input
              label={t('drugsConsumed')}
              type="text"
              id="drugsConsumed"
              error={errors.drugsConsumed}
              register={register}
              required
            />
            <Input
              label={t('drugsConsumedHowMuch')}
              type="text"
              id="drugsConsumedHowMuch"
              error={errors.drugsConsumedHowMuch}
              register={register}
              required
            />
          </>
        )}
      </Container>
      <Container>
        <Select
          label={t('oralHygieneProblems')}
          id="oralHygieneProblems"
          options={trueFalseOptions}
          error={errors.oralHygieneProblems}
          register={register}
          required
        />
        <Select
          label={t('faceOrMouthBlow')}
          id="faceOrMouthBlow"
          options={trueFalseOptions}
          error={errors.faceOrMouthBlow}
          register={register}
          required
        />
        <Select
          label={t('previousOrthodonticTreatment')}
          id="previousOrthodonticTreatment"
          options={trueFalseOptions}
          error={errors.previousOrthodonticTreatment}
          register={register}
          required
        />
        <Select
          label={t('frequentNauseaOrVomiting')}
          id="frequentNauseaOrVomiting"
          options={trueFalseOptions}
          error={errors.frequentNauseaOrVomiting}
          register={register}
          required
        />
      </Container>
      <Container>
        <Select
          label={t('alcoholConsumption')}
          id="alcoholConsumption"
          options={trueFalseOptions}
          error={errors.alcoholConsumption}
          register={register}
          required
        />
        {formValues?.alcoholConsumption === 'true' && (
          <Input
            label={t('alcoholConsumedHowMuch')}
            type="text"
            id="alcoholConsumedHowMuch"
            error={errors.alcoholConsumedHowMuch}
            register={register}
            required
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('other')}
          id="other"
          options={trueFalseOptions}
          error={errors.other}
          register={register}
          required
        />
        {formValues?.other === 'true' && (
          <Input
            label={t('otherWhich')}
            type="text"
            id="otherWhich"
            error={errors.otherWhich}
            register={register}
            required
          />
        )}
      </Container>
      <Container>
        <Input
          label={t('reasonForVisit')}
          type="text"
          id="reasonForVisit"
          error={errors.reasonForVisit}
          register={register}
          required
        />
        <Select
          label={t('satisfiedWithSmile')}
          id="satisfiedWithSmile"
          options={trueFalseOptions}
          error={errors.satisfiedWithSmile}
          register={register}
          required
        />
        {formValues?.satisfiedWithSmile === 'false' && (
          <Input
            label={t('satisfiedWithSmileWhy')}
            type="text"
            id="satisfiedWithSmileWhy"
            error={errors.satisfiedWithSmileWhy}
            register={register}
            required
          />
        )}
      </Container>
      <Container>
        <Select
          label={t('familyOrthodonticTreatment')}
          id="familyOrthodonticTreatment"
          options={trueFalseOptions}
          error={errors.familyOrthodonticTreatment}
          register={register}
          required
        />
      </Container>
    </>
  );
};

export default Dental;
