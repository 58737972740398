/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
const formatBoolean = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};

const verificarValoresNoVacios = (arr) => {
  // Iterar sobre cada objeto en el array
  if (!arr.length) return true;
  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];

    // Iterar sobre cada propiedad del objeto
    for (const key in obj) {
      // Verificar si el valor es vacío o contiene solo espacios en blanco
      if (obj[key] === '' || obj[key].trim() === '') {
        return true;
      }
    }
  }
  return false;
};

export const formatValues = (values = {}) => {
  const formattedValues = {};
  const keys = Object.keys(values);

  keys.forEach((key) => {
    formattedValues[key] = formatBoolean(values[key]);
    if (key === 'gender') {
      formattedValues[key] = values[key] === 'M' ? 'Masculino' : 'Femenino';
    }
    if (values[key] === '' || values[key] === null || values[key] === undefined) {
      // eslint-disable-next-line no-param-reassign
      delete formattedValues[key];
    }
    if (Array.isArray(values[key]) && verificarValoresNoVacios(values[key])) {
      // eslint-disable-next-line no-param-reassign
      delete formattedValues[key];
    }
  });

  return formattedValues;
};
